import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const CupoService = {
  contadores_productos_agrupados,
  listado_productos_agrupados_asignados,
  listado_productos_agrupados_sin_asignados,
  asignar_agrupador_producto,
  eliminar_producto_agrupador,
  listado_productos_agrupados_con_advertencias
};
function listado_productos_agrupados_con_advertencias(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}cupo/v1/interno/listado_productos_agrupados_con_advertencias`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Relacion Laboral
function contadores_productos_agrupados(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}cupo/v1/interno/contadores_productos_agrupados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listado_productos_agrupados_asignados(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}cupo/v1/interno/listado_productos_agrupados_asignados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

// 
function listado_productos_agrupados_sin_asignados(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}cupo/v1/interno/listado_productos_agrupados_sin_asignados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function asignar_agrupador_producto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cupo/v1/interno/asignar_agrupador_producto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};



function eliminar_producto_agrupador(data) {
  const requestOptions = {
    method: "DELETE",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cupo/v1/interno/eliminar_producto_agrupador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};