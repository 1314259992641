import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const SolicitudService = {
  listarSolicitudes,
  solicitudAll,
  solicitudCrear,
  solicitudActualizar,
  solicitudEstados,
  aprobarSolicitud,
  entregaSolicitud,
  listadoEntregaSolicitud,
  finalizarsolicitud,
};

function listarCat() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarSolicitudes(datos = null) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}v1/solicitud/listarsolicitudes${datos}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};


// solicitud/{id}/all
function solicitudAll(id) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}v1/solicitud/solicitud/${id}/all`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};
// solicitud/estados = POST

function solicitudEstados() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}v1/solicitud/estados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

// solicitud = POST
function solicitudCrear(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}v1/solicitud/solicitud`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};


function aprobarSolicitud(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}v1/solicitud/aprobarsolicitud`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function entregaSolicitud(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}v1/solicitud/entregasolicitud`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function finalizarsolicitud(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}v1/solicitud/finalizarsolicitud`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listadoEntregaSolicitud(id_detalle_solicitud) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}v1/solicitud/entregasolicitud/${id_detalle_solicitud}/historial`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};
// solicitud = PUT

function solicitudActualizar(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}v1/solicitud/solicitud`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};
function filtrarProductos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/filtrarProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};


function listarCategoriasTienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarCategoriasTienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarSubCategoriasTienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarSubCategoriasTienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarMasVistosSubCategoria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarMasVistosSubCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};


function listarMasRecientesSubCategoria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarMasRecientesSubCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarMasVistos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/listarMasVistos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function listarMasRecientes(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarMasRecientes?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarTiendaProductos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarTiendaProductos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarMatenimientoProductos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarMatenimientoProductos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerProducto(id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/obtenerProducto/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarComboAgrupadores(agrupador) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/listarComboAgrupadores?${agrupador}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearProductoKipit(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/crearProductoKipit`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function aprobacionprecios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/aprobacionprecios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarProductoKipit(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/actualizarProductoKipit`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearProducto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/crearProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function eliminarProducto(data) {
  const requestOptions = {
    method: "DELETE",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/eliminarProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



