import React, { useEffect, useState } from 'react';
import { Button, Layout, Result, Progress, Image } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import iconoKipit from "../../src/assets/images/KIPIT-LOGO-VIOLETA.png";
import { UrlLogout } from "../jwt/_services/Url.service";
const { Header, Content, Footer } = Layout;

const ErrorInterno = ({ contenido }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const newProgress = prevProgress + 5;
                localStorage.clear();
                if (newProgress >= 100) {
                    clearInterval(interval);
                    window.location.href = UrlLogout;
                }
                return newProgress;
            });
        }, 100);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
        </>
        // <Layout>
        //     <Header
        //         style={{
        //             background: "white",
        //             position: 'sticky',
        //             top: 0,
        //             zIndex: 1,
        //             width: '100%',
        //             display: 'flex',
        //             alignItems: 'center',
        //         }}
        //     >
        //         <div className="demo-logo" />
        //         <img
        //             height={75}
        //             width={125}
        //             src={iconoKipit}
        //             alt="Kipit Logo"
        //         />
        //     </Header>
        //     <Content
        //         style={{
        //             padding: '0 48px',
        //             height: "100vh",
        //             display: 'flex',
        //             flexDirection: 'column',
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //         }}
        //     >
        //         <Result
        //             status="500"
        //             title="Serás redirigido automáticamente en unos momentos..."
        //             subTitle={contenido}
        //             extra={
        //                 <div style={{ textAlign: 'center' }}>
        //                     <Progress
        //                         percent={progress}
        //                         status="active"
        //                         style={{ width: '300px', marginTop: '20px' }}
        //                     />
        //                 </div>
        //             }
        //         />
        //     </Content>
        // </Layout>
    );
};

export default ErrorInterno;
