import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";

export const ColoresService = {
    listarColores,
    EliminarColor,
    CrearColor,
    EditarColores,
    CambiarEstado,
};


function listarColores() {
    const requestOptions = {
      method: "GET",
      headers: AuthHeader(),
    };
    return fetch(`${Url}color/listar_colores`, requestOptions)
      .then(HandleResponse)
      .then((response) => {
        return response;
      });
  }

function EliminarColor(data) {
    const requestOptions = {
      method: "DELETE",
      headers: AuthHeader(),
      body: JSON.stringify({ data }),
    };
    return fetch(`${Url}color/deleteColor`, requestOptions)
      .then(HandleResponse)
      .then((response) => {
        return response;
      });
}

function CrearColor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}color/crearColor`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EditarColores(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}color/editarColores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function CambiarEstado(id_color, estado) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify({} ),
  };

  return fetch(`${Url}color/estadocolor/${id_color}/${estado}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};
