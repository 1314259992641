import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { UbicacionMapaService as MapBox } from "./UbicacionMapa.service";

//devuelve las funciones
export const ComercialService = {
    ExisteCliente,
    obtenerUbicacionesJson,
    obtenerLugarJson,
    crearProspecto,
    obtenerNivelAcademico,
    listarNivelAcademico,
    obtenerNacionalidad,
    obtenerRelacionLaboral,
    obtenerCargo,
    registroCliente,
    actualizarCliente,
    editarcliente,
    eliminarCliente,
    obtenerClienteFiltrado,
    obtenerCiudad,
    obtenerLaboralCargo,
    comboPlanes_x_Prestador_clientes,
    ObtenerClientePlanesPrestador,
    EliminarPlanesInteresado,
    ExisteCedula_Cliente_Conyuge,
    FusionarPDFs,
    InfoRegistroCivil,
    ExisteCorreo,
    ListarClientes,
    FiltrarClientes,
    ReImpresionContrato,
    GenerarOperacionContrato,
    GenerarHojaDeRuta,
    ContratoMovimiento,
    GenerarConvenioPDF,
    SupervisionBuro,
    obtenerClientesAsignarAsesor,
    AsignarAsesor,
    ReportExcelAsignarAsesor,
    DocumentosFiduciaria,
    LeerPagosExcel,
    ListarMovimientos,
    RevertirMovimientoPago,
    FiltrarMovimientosPagos,
    ContratoDetalleFirmaCliente,
    ContratoDetalleFirmaAsesoraFinanciera,
    ContratoDetalleFirmaAmbiensa,
    ContratoDetalleFirmaFiduciaria,
    FinalizarContratoOperacion,
    CorreoValidarBuro,
    ListarClientesReasignar,
    ReasignarAsesor,
    listarRegistroCliente,
    registrarClienteUsuario,
    selects,
    listarRegistroClienteById,
    listarRegistroClienteByIdPublic,
    obtenercliente,
    obtenerproyectobyempresa,
};


/* Traer Informacion Registro Civil */
function InfoRegistroCivil(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/InfoRegistroCivil`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function obtenerproyectobyempresa(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}empresa/obtenerproyectobyempresa`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ListarClientesReasignar(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}ReasignarAsesor/ListarClientesReasignar`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ReasignarAsesor(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}ReasignarAsesor/ReasignarAsesor`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Ventas */
/* Listo todos los clientes qeu tengan al menos un plan */
function ListarClientes(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ListarClientes`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function CorreoValidarBuro(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/CorreoValidarBuro`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function FinalizarContratoOperacion(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/FinalizarContratoOperacion`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ContratoDetalleFirmaCliente(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ContratoDetalleFirmaCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ContratoDetalleFirmaAsesoraFinanciera(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ContratoDetalleFirmaAsesoraFinanciera`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ContratoDetalleFirmaAmbiensa(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ContratoDetalleFirmaAmbiensa`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ContratoDetalleFirmaFiduciaria(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ContratoDetalleFirmaFiduciaria`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function FiltrarMovimientosPagos(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Pagos/FiltrarMovimientosPagos`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function SupervisionBuro(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/SupervisionBuro`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function DocumentosFiduciaria(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/DocumentosFiduciaria`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function RevertirMovimientoPago(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Pagos/RevertirMovimientoPago`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Listo todos los clientes qeu tengan al menos un plan */
function GenerarConvenioPDF(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/GenerarConvenioPDF`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Guardo los pagos en base a un excel */
function LeerPagosExcel(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Pagos/LeerPagosExcel`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ListarMovimientos(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Pagos/ListarMovimientos`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Finalizar el convenio imprimiendo el PDF y guardando la informacion */
function ReImpresionContrato(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ReImpresionContrato`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ReportExcelAsignarAsesor(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}AsignarAsesor/ReportExcelAsignarAsesor`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Filtro de ventas */
function FiltrarClientes(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/FiltrarClientes`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function GenerarOperacionContrato(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/GenerarOperacionContrato`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ContratoMovimiento(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/ContratoMovimiento`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function GenerarHojaDeRuta(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}Ventas/GenerarHojaDeRuta`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function AsignarAsesor(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}AsignarAsesor/AsignarAsesor`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function obtenerClienteFiltrado(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/obtenerClienteFiltrado`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Listar clientes sin asesor asignado */
function obtenerClientesAsignarAsesor(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}AsignarAsesor/obtenerClientes`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* obtengo el combo filtrado de los planes qeu tiene ese prestador */
function comboPlanes_x_Prestador_clientes(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}param_prestadores/comboPlanes_x_Prestador_clientes`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ObtenerClientePlanesPrestador(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/ObtenerClientePlanesPrestador`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function EliminarPlanesInteresado(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/EliminarPlanesInteresado`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function registroCliente(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/registroCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* Funcion para Validar si ya esta registrado el correo */
function ExisteCorreo(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/ExisteCorreo`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function actualizarCliente(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/actualizarCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function editarcliente(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/editarcliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function eliminarCliente(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/eliminarCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function listarNivelAcademico() {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
    };
    return fetch(
        `${Url}prospectos/listarNivelAcademico`,
        requestOptions
    ).then(HandleResponse);
}

function obtenerUbicacionesJson(data) {
    const requestOptions = { method: "GET", headers: AuthHeader() };
    return fetch(`${MapBox.urlBusqueda}${data}${MapBox.parametrosBusqueda}${MapBox.apiToken}`, requestOptions)
        .then(HandleResponse);
}


function obtenerLugarJson(data) {
    const requestOptions = { method: "GET", headers: AuthHeader() };
    return fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${data}.json?access_token=${MapBox.apiToken}`, requestOptions)
        .then(HandleResponse);
}

function crearProspecto(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}prospectos/crearProspecto`, requestOptions).then(HandleResponse);
}

function obtenerNacionalidad() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader()
    };
    return fetch(`${Url}nacionalidad/obtenerNacionalidad`, requestOptions).then(
        HandleResponse
    );
}

function obtenerNivelAcademico() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        body: JSON.stringify(),
    };
    return fetch(`${Url}nivelacademico/obtenerNivelAcademico`, requestOptions).then(HandleResponse);
}



function obtenerCargo() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        body: JSON.stringify(),
    };
    return fetch(`${Url}cargo/obtenerCargo`, requestOptions).then(HandleResponse);
}

function obtenerCiudad() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        body: JSON.stringify(),
    };
    return fetch(`${Url}ciudad/obtenerCiudad`, requestOptions).then(HandleResponse);
}

function obtenerRelacionLaboral() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        body: JSON.stringify(),
    };
    return fetch(`${Url}relacionlaboral/obtenerRelacionLaboral`, requestOptions).then(HandleResponse);
}

function obtenerLaboralCargo(data) {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargolaboral/obtenerLaboralCargo`, requestOptions).then(HandleResponse);
}

/* Fncino para registrar los planes del interesado */
function ExisteCedula_Cliente_Conyuge(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/ExisteCedula_Cliente_Conyuge`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function FusionarPDFs(data) {
    const requestOptions = {
        method: "post",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/FusionarPDFs`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
//Listar los clientes registrados para una futura aprobacion

function listarRegistroClienteById(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/listarRegistroClienteById`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function ExisteCliente(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/ExisteCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function listarRegistroClienteByIdPublic(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}auths/listarRegistroClienteByIdPublic`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function listarRegistroCliente() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        //body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/listarRegistroCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function obtenercliente(idcliente) {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
    };
    return fetch(`${Url}cliente/obtenercliente/${idcliente}`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function registrarClienteUsuario(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/registrarClienteUsuario`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function selects() {
    const requestOptions = {
        method: "GET",
        headers: AuthHeader(),
        //body: JSON.stringify(data),
    };
    return fetch(`${Url}cliente/obtenerSelect`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}