import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//devuelve las funciones
export const DisenoService = {
  
  // Proyecto
  obtenerProyecto,
  validarProyecto,
  crearProyecto,
  actualizarProyecto,
  eliminarProyecto,
  llenarComboProyecto,
  // Proyecto
  obtenerEtapa,
  crearEtapa,
  actualizarEtapa,
  eliminarEtapa,
  llenarComboEtapa,
};
//proyecto
function llenarComboProyecto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}proyecto/llenarComboProyecto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}proyecto/listarProyecto`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}

function validarProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proyecto/validarProyecto`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}

function crearProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proyecto/crearProyecto`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}
function actualizarProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proyecto/actualizarProyecto`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}
function eliminarProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proyecto/eliminarProyecto`,
    requestOptions
  ).then(HandleResponse);
  
}

//etapa
function llenarComboEtapa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}etapa/llenarComboEtapa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerEtapa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}etapa/listarEtapas`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}

function crearEtapa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}etapa/crearEtapa`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}
function actualizarEtapa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}etapa/actualizarEtapa`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}
function eliminarEtapa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}etapa/eliminarEtapa`,
    requestOptions
  ).then(HandleResponse);
  
}
