import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import auth from "../../redux/settings/authReducer";

export const CargarPreciosService = {
    CombosProveedores,
    CargarPrecios,
    ValidarPrecios,
    // CargarClientes,
    // ActualizarPrecioCliente,
    ListarPrecioClientes,
    FiltrarPrecioCliente,
    AsignacionPrecioClientes,
    listarPrecioDescripcion,
    FiltrarPrecioDescripcion,
    FiltrarEmpresas,

};
const cache = JSON.parse(localStorage.getItem("currentUser"));

function CombosProveedores(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/CombosProveedores`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function CargarPrecios(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/CargarPrecios`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

function ValidarPrecios(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/ValidarPrecios`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
// function CargarClientes(data) {
//     const requestOptions = {
//         method: "POST",
//         headers: AuthHeader(),
//         body: JSON.stringify(data),
//     };
//     return fetch(`${Url}cargarprecios/CargarClientes`, requestOptions)
//         .then(HandleResponse)
//         .then((response) => {
//             return response;
//         });
// }
// function ActualizarPrecioCliente(data) {
//     const requestOptions = {
//         method: "POST",
//         headers: AuthHeader(),
//         body: JSON.stringify(data),
//     };
//     return fetch(`${Url}cargarprecios/ActualizarPrecioCliente`, requestOptions)
//         .then(HandleResponse)
//         .then((response) => {
//             return response;
//         });
// }
function ListarPrecioClientes(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/ListarPrecioClientes`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function listarPrecioDescripcion(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/listarPrecioDescripcion`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function FiltrarPrecioDescripcion(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/FiltrarPrecioDescripcion`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function FiltrarPrecioCliente(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/FiltrarPrecioCliente`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function FiltrarEmpresas(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/FiltrarEmpresa`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
function AsignacionPrecioClientes(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/AsignacionPrecioClientes`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/* function AprobarAnularEliminacionesDePagos(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify({ id_perfil: (cache != null ? cache.user.id_perfil : ''), ...data }),
    };
    return fetch(`${Url}cargarprecios/AprobarAnularEliminacionesDePagos`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}
/*function CombosVerificacionDePagos(data) {
    const requestOptions = {
        method: "POST",
        headers: AuthHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${Url}cargarprecios/CombosVerificacionDePagos`, requestOptions)
        .then(HandleResponse)
        .then((response) => {
            return response;
        });
}

 */
