import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";
import { UtilService } from "./Util.service";

export const TesoreriaService = {
  getPagosDepositoTransferencias,
  aprobarPagos,
  comboTesoreria,
  getDetalleOrden,
  verificarPagosTarjeta,
  verificarPagos,
  getDetallePagosDT,
  getPagosRechazodoAnulados,
  getPagosTarjeta,
  obtenerdatapagos,
  SubirArchivo,
  obtenerdatatempoarchivo,
  combosConciliacion,
  obtenecontabilizacionFiltrado,
  obtenecontabilizacionvisualizar,
  obteneracciones,
  updatedatatempoarchivo,
  obtenerdatatemp,
  listar_bancos,
  obtenerparametrotemp,
  obtenercomboestablecimiento,
  obtenerimporte,
  guardartempodata,
  obtenerdatatemprevision,
  guardardataconciliacion,
  obtenerdatadetalle,
  obteneropcionestransaciones,
  obteneropcionestransacion,
  reverso_a_revision,
  guardardatacontabilizacion,
  eliminarconciliacion
};

function eliminarconciliacion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/eliminarconciliacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardardatacontabilizacion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/guardardatacontabilizacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function reverso_a_revision(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}tesoreria/reverso_a_revision_conciliacion`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obteneropcionestransacion() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/obteneropcionestransacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obteneropcionestransaciones() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/obteneropcionestransaciones`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerdatadetalle(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/obtenerdatadetalle`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardardataconciliacion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/guardardataconciliacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerdatatemprevision() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/obtenerdatatemprevision`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardartempodata(data) {


  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/guardartempdata`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerimporte() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/importe`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenercomboestablecimiento() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/obtenercomboestablecimiento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerparametrotemp(data) {
  const formatdata = UtilService.removeReactComponents(data);
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(formatdata),
  };
  return fetch(`${Url}tesoreria/obtenerparametrotemp`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listar_bancos(data) {
  const formatdata = UtilService.removeReactComponents(data);
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(formatdata),
  };
  return fetch(`${Url}tesoreria/listar_bancos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obteneracciones() {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/obteneracciones`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenecontabilizacionvisualizar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tesoreria/obtenecontabilizacionvisualizar`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenecontabilizacionFiltrado(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/obtenecontabilizacionFiltrado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function obtenerdatatemp() {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tesoreria/datatemp`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function updatedatatempoarchivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/updatedatatempoarchivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function combosConciliacion(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/combosconciliacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function obtenerdatatempoarchivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/obtenerdatatempoarchivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function SubirArchivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/SubirArchivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerdatapagos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tesoreria/obtenerdatapagos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function getPagosDepositoTransferencias(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/getPagosDepositoTransferencias`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function getPagosTarjeta(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/getPagosTarjeta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function aprobarPagos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/aprobarPagos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
  // .catch(() => {
  // 	return NetworkService.errorRed();
  // });
}
function comboTesoreria(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/comboTesoreria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function getDetalleOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/getDetalleOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function verificarPagos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/verificarPagos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function verificarPagosTarjeta(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/verificarPagosTarjeta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function getDetallePagosDT(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/getDetallePagosDT`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function getPagosRechazodoAnulados(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tesoreria/getPagosRechazodoAnulados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}