import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const ProductosService = {
  //definiciones
  listarProductos,
  storeClickProductosVistos,
  listadoCuposDisponibles,
  listado_combo_productos_agrupados,
  obtener_producto_con_agrupados
};

//Relacion Laboral
function listarProductos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}productos/listarProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}

function storeClickProductosVistos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/storeClickProductosVistos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}

function listadoCuposDisponibles(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}productos/listadoCuposDisponibles`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listado_combo_productos_agrupados(id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/listado_combo_productos_agrupados/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtener_producto_con_agrupados(id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/obtener_producto_con_agrupados/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}