import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { UtilService } from "./Util.service";
// import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const CarroComprasService = {
  //definiciones
  crearCarroCompra,
  crearCarroCompraCupo,
  EliminarProductoCarro,
  obtenerCarroCompra,
  ObtenerProductoCarroDetalle,
  cargarItems,
  cargarCarroAnterior,
  obtenerTarifaEnvioCambiaCarro,
  EliminarProductoCarroCupo,
  // cargarCarroPedir,
  CompraDirectaCupo,
};

function CompraDirectaCupo(data) {
  const formatdata = UtilService.removeReactComponents(data);
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(formatdata),
  };
  return fetch(`${Url}carrocompras/CompraDirectaCupo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function cargarItems(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carrocompras/obtenerItemsTotal`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function crearCarroCompra(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carrocompras/crearCarroCompra`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EliminarProductoCarro(id_detalle) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ "id_detalle": id_detalle }),
  };
  return fetch(`${Url}carrocompras/EliminarProductoCarro`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EliminarProductoCarroCupo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carrocompras/EliminarProductoCarroCupo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function ObtenerProductoCarroDetalle(session_usuario, id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}carrocompras/ObtenerProductoCarroDetalle/${session_usuario}/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerCarroCompra(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carrocompras/obtenerCarroCompra`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
// function obtenerCarroCompra(data) {
//   const requestOptions = {
//     method: "GET",
//     headers: AuthHeader(),
//   };
//   return fetch(`${Url}carrocompras/obtenerCarroCompra?${data}`, requestOptions)
//     .then(HandleResponse)
//     .then((response) => {
//       return response;
//     });
// }



function obtenerTarifaEnvioCambiaCarro(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}carrocompras/obtenerTarifaEnvioCambiaCarro`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

}





function cargarCarroAnterior(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}carrocompras/obtenerCarroAnterior`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function crearCarroCompraCupo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carrocompras/crearCarroCompraCupo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
