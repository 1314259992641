import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const InventarioService = {
  //definiciones
  crearProducto,
  crearProductoKipit,
  actualizarProductoKipit,
  listarMantenimientoProductos,
  listarTiendaProductos,
  eliminarProducto,
  obtenerProducto,
  obtenerProductoMantenimiento,
  listarComboAgrupadores,
  aprobacionprecios,
  listarSubCategoriasTienda,
  listarCategoriasTienda,
  listarMasVistos,
  listarMasRecientes,
  listarMasVistosSubCategoria,
  listarMasRecientesSubCategoria,
  listarCat,
  listarProductos,
  filtrarProductos,
  listarPublicProductos,
  crearListaDeseos,
  obtenerListaDeseos,
  EliminarProductoLista,
  crearVarios,
  listarRelacionados,
  deseosCarroCompra,
  crearCategoria,
  eliminarCategoria,
  obtenerCategoria,
  actualizarCategoria,
  obtenerCategoriaNombre,
  obtenerColeccionNombre,
  listarRuta,
  cargaMasivaProducto,
  inactivarProductos,
  actualizarEstadoProductos,
  listarColores,
  listarProductoAgrupador,
  obtenerProductoPublic,
  getNombreCategoria,
  productResult,
  productosBusqueda,
  listarMasVendidos,
  listarCategoriaMantenimiento,
  cambiarEstadoCategoria,
  listarTags,
  updateMasivaProducto,
  listarColecciones,
  llenarComboMarca,
  listarDimensiones,
  cambiarEstadoDimesion,
  crearDimension,
  obtenerDimensionEditar,
  actualizarDimension,
  eliminarDimension,
  cambiarEstadoColeccion,
  Crearcoleccion,
  actualizarcole,
  eliminarColeciones,
  ColeccionEditar,
  listarCategoriasMenu,
  
  listarProductosActivos,
  getCategoriasPanel,
  savePanelProductos,
  removePanelProducto,
  listarKipigreen
};
function listarColecciones() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarColecciones`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function obtenerColeccionNombre(nombre) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(nombre),
  };
  return fetch(`${Url}inventario/filtrarColeccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function cambiarEstadoColeccion(id_coleccion, estado) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify({} ),
  };

  return fetch(`${Url}inventario/cambiarEstadoColeccion/${id_coleccion}/${estado}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      // console.log(response)
      return response;
    });

};



function listarTags() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarTags`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarCat() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarProductos() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function filtrarProductos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/filtrarProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function productResult(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/productResult`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};



function listarCategoriasTienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarCategoriasTienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarCategoriasMenu(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarCategoriasMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarSubCategoriasTienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarSubCategoriasTienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarMasVistosSubCategoria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarMasVistosSubCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};


function listarMasRecientesSubCategoria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/listarMasRecientesSubCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function listarMasVistos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/listarMasVistos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarMasVendidos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/listarMasVendidos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function getNombreCategoria(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/getCategoriaNombre/${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarMasRecientes(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarMasRecientes?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarTiendaProductos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarTiendaProductos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarPublicProductos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarPublicProductos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function productosBusqueda(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/productosBusqueda?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarMantenimientoProductos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarMantenimientoProductos?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerProducto(id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/obtenerProducto/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerProductoMantenimiento(id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/obtenerProductoMantenimiento/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerProductoPublic(id_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/obtenerProductoPublic/${id_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarComboAgrupadores(agrupador) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/listarComboAgrupadores?${agrupador}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearProductoKipit(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/crearProductoKipit`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function aprobacionprecios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/aprobacionprecios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarProductoKipit(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/actualizarProductoKipit`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearProducto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/crearProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function eliminarProducto(data) {
  const requestOptions = {
    method: "DELETE",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/eliminarProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function crearCategoria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/crearCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function eliminarCategoria(data) {
  // debugger;
  const requestOptions = {
    method: "DELETE",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/eliminarCategoria/${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearListaDeseos(data) {
  // console.log(data);
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}inventario/crearListaDeseos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      // console.log('response');
      // console.log(response);
      return response;
    });
}

function obtenerListaDeseos(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/obtenerListaDeseos?${data}`, requestOptions)

    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerCategoria(id_categoria) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}inventario/obtenerCategoria/${id_categoria}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function obtenerColeccion(id_coleccion) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}inventario/obtenerColeccion/${id_coleccion}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function EliminarProductoLista(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({data}),
  };
  return fetch(`${Url}inventario/EliminarProductoLista`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarCategoria(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/actualizarCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearVarios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ "productos": data }),
  };
  return fetch(`${Url}inventario/crearVarios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      // console.log('response');
      // console.log(response);
      return response;
    });
}

function listarRelacionados(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({data}),
  };
  return fetch(`${Url}inventario/listarRelacionados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function deseosCarroCompra(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/deseosCarroCompra`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function obtenerCategoriaNombre(nombre) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(nombre),
  };
  return fetch(`${Url}inventario/filtrarCategoria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function listarRuta() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(nombre),
  };
  return fetch(`${Url}inventario/listaRuta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function cargaMasivaProducto(file){//
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(null),
   body: file,
   
  };
  // console.log(file)
  return fetch(`${Url}inventario/cargaMasivaProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function inactivarProductos(data){
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
   body: JSON.stringify({
    "lista":data
   })
   
  };
  // console.log(data)
  return fetch(`${Url}inventario/inactivarProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function actualizarEstadoProductos(data){
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
   body: JSON.stringify({
    "lista":data
   })
   
  };
  // console.log(data)
  return fetch(`${Url}inventario/actualizarEstadoProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarColores() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/listaColores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
} 

function listarProductoAgrupador() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/listarProductoAgrupador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function listarCategoriaMantenimiento() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarCategoriaMantenimiento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};
function cambiarEstadoCategoria(id,estado) {
  // debugger;
  
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/cambiarEstadoCategoria/${id}/${estado}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function updateMasivaProducto(file){
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(null),
   body: file,
   
  };
  // console.log(file)
  return fetch(`${Url}inventario/updateMasivoProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function llenarComboMarca(){
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  //  body: file,
   
  };
  
  return fetch(`${Url}inventario/comboMarcas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function listarDimensiones() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    //body: JSON.stringify( data ),
  };

  return fetch(`${Url}inventario/listarDimensiones`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function cambiarEstadoDimesion(id,estado) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/cambiarEstadoDimesion/${id}/${estado}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function eliminarDimension(data) {
  const requestOptions = {
    method: "DELETE",
    headers: AuthHeader(),
  };
  return fetch(`${Url}inventario/eliminarDimension/${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function crearDimension(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/crearDimension`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerDimensionEditar(id_dimesion_producto) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}inventario/obtenerDimensionEditar/${id_dimesion_producto}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarDimension(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}inventario/actualizarDimension`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//colecciones

function Crearcoleccion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/Crearcoleccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarcole(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/actualizarcole`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarColeciones(id) {
  // debugger;
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(id),
  };
  return fetch(`${Url}inventario/eliminarColecion/${id}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function ColeccionEditar(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/ColeccionEditar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function getCategoriasPanel(tipoNegocioActiva,pestanaActiva) {
  const data = {
    negocio: tipoNegocioActiva,
    pestania: pestanaActiva
  };
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}inventario/getCategoriasPanel`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarProductosActivos(categoria,tipoNegocio,pestanaActiva) { 
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({categoria,tipoNegocio,pestanaActiva}),
  };

  return fetch(`${Url}inventario/getProductosActivados`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
     // console.log(response)
      return response;
    });
}

function savePanelProductos(categoria,tipoNegocio,pestanaActiva,nuevosProductosSeleccionados) {
   
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({categoria,tipoNegocio,pestanaActiva,nuevosProductosSeleccionados}),
  };

  return fetch(`${Url}inventario/savePanelProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
} 

function removePanelProducto(idProducto, idTipoPagina, idPestania) {
   
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({idProducto, idTipoPagina, idPestania}),
  };

  return fetch(`${Url}inventario/removePanelProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
} 
function listarKipigreen(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}inventario/listarKipigreen?${data}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}