// SETTINGS CONSTANTS
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";
export const IS_CARGANDO = "IS_CARGANDO";
export const HEADERBG = "HEADERBG";

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = "FETCH_CHAT_SUCCESS";
export const SELECTED_CHAT = "SELECTED_CHAT";
export const SEARCH_USER = "SEARCH_USER";
export const MSG_SUBMIT = "MSG_SUBMIT";
export const CHAT_CLOSE = "CHAT_CLOSE";

// TODO CONSTANTS
export const FETCH_TODO_REQUEST = "FETCH_TODO_REQUEST";
export const FETCH_TODO_SUCCESS = "FETCH_TODO_SUCCESS";
export const FETCH_TODO_FAILURE = "FETCH_TODO_FAILURE";
export const TOGGLE_STARRED_TODO = "TOGGLE_STARRED_TODO";
export const SET_VISIBILITY_FILTER_TODO = "SET_VISIBILITY_FILTER_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const TODO_DETAILS = "TODO_DETAILS";
export const FILTER_TODO = "FILTER_TODO";
export const COMPLETE_TODO = "COMPLETE_TODO";
export const UPDATE_TODO = "UPDATE_TODO";
export const ADD_TODO = "ADD_TODO";

// NOTES CONSTANTS

export const SELECTED_NOTES = "SELECTED_NOTES";
export const SEARCH_NOTES = "SEARCH_NOTES";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const ADD_NOTE = "ADD_NOTE";

// CONTACT CONSTANTS
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
export const FILTER_CONTACT = "FILTER_CONTACT";
export const TOGGLE_STARRED_CONTACT = "TOGGLE_STARRED_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

// EMAIL CONSTANTS
export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";

//AUTH CONSTANTS
export const LOGIN_RENEW_TOKEN = "LOGIN_RENEW_TOKEN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = 'LOGIN_ERROR';

//ALERTS
export const SUBMENUS_ALERT = 'SUBMENUS_ALERT';


//SESSION

export const ID_SESSION_CARRO_COMPRAS = 'ID_SESSION_CARRO_COMPRAS';
export const IS_AMBIENSA = 'IS_AMBIENSA';
// SET_CLIENTE
export const SET_CLIENTE = 'SET_CLIENTE';
export const RESET_CLIENTE = 'RESET_CLIENTE';

//SOLICITUD
export const ESTADOS_SOLICITUD = 'ESTADOS_SOLICITUD';
export const ESTADO_ACTUAL = 'ESTADO_ACTUAL';
export const ADD_SOLICITUDES = 'ADD_SOLICITUDES';
export const ACTUAL_SOLICITUD = 'ACTUAL_SOLICITUD';
export const UPDATE_SOLICITUD = 'UPDATE_SOLICITUD';
export const CANTIDADES_SOLICITUD = 'CANTIDADES_SOLICITUD';
export const FINALIZAR_SOLICITUD = 'FINALIZAR_SOLICITUD';


//MODAL
export const MODAL_INFORMATIVO = 'MODAL_INFORMATIVO';
export const MODAL_ACEPTACION = 'MODAL_ACEPTACION';

export const RESPUESTA = 'RESPUESTA';
export const MODAL_LOGIN = 'MODAL_LOGIN';
export const MODAL_TEMPLATE = 'MODAL_TEMPLATE';
export const MODAL_TEMPLATE_CONTENT = 'MODAL_TEMPLATE_CONTENT';
export const MODAL_MOTIVO = 'MODAL_MOTIVO';

//DIRECCIONES

export const ADD_PROYECTOS = 'ADD_PROYECTOS';
export const ADD_PROYECTO = 'ADD_PROYECTO';
export const PROYECTO_ACTUAL = 'PROYECTO_ACTUAL';
export const ADD_DIRECCIONES = 'ADD_DIRECCIONES';
export const ADD_DIRECCION = 'ADD_DIRECCION';
export const DIRECCION_ACTUAL = 'DIRECCION_ACTUAL';
export const GET_PROYECTOS = 'GET_PROYECTOS';
export const ADD_COMBO_AGRUPADOS = 'ADD_COMBO_AGRUPADOS';
export const ADD_PRODUCTO_AGRUPADO = 'ADD_PRODUCTO_AGRUPADO';
export const RESET_PROYECTOS = 'RESET_PROYECTOS';

//FACTURACION

export const ADD_DATOS_FACTURACION = 'ADD_DATOS_FACTURACION';

//CUPO
export const COMPRA_PUERTAS = 'COMPRA_PUERTAS';
export const CUPO_COMPRA = 'CUPO_COMPRA';
export const TIPO_COMPRA = 'TIPO_COMPRA';
export const SESSION_USUARIO = 'SESSION_USUARIO';
export const ID_USUARIO = 'ID_USUARIO';
export const PROYECTO_CUPO = 'PROYECTO_CUPO';
export const RESET_CUPO = 'RESET_CUPO';
export const COMBO_AGRUPADOS = 'COMBO_AGRUPADOS';
export const PRODUCTO_AGRUPADO = 'PRODUCTO_AGRUPADO'; 
// export const SET_CARGANDO = 'SET_CARGANDO';
export const SET_ASIGNADOS = 'SET_ASIGNADOS';
export const SET_SIN_ASIGNAR = 'SET_SIN_ASIGNAR';
export const SET_CON_ADVERTENCIAS = 'SET_CON_ADVERTENCIAS';
export const SET_CONTADOR_ASIGNADOS = 'SET_CONTADOR_ASIGNADOS';
export const SET_CONTADOR_SIN_ASIGNAR = 'SET_CONTADOR_SIN_ASIGNAR';
export const SET_CONTADOR_CON_ADVERTENCIAS = 'SET_CONTADOR_CON_ADVERTENCIAS';