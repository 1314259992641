import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//devuelve las funciones

//devuelve las funciones
export const MantenimientosService = {
  //comunicaciones
  obtenerAlertasFiltrado,
  listarSubmenu,
  listarCodigoStatus,
  listarTipoAlerta,
  getAlertas,
  crearAlertas,
  actualizarAlerta,
  eliminarAlerta,

  listarTipo,
  crearTipo,
  actualizarTipo,
  eliminarTipo,

  listarStatus,
  crearStatus,
  actualizarStatus,
  eliminarStatus,

  //Menu
  listarMenu,
  crearMenu,
  actualizarMenu,
  eliminarMenu,
  validarnameMenu,
  llenarComboMenu,

  obtenerUsuarios,
  crearUsuario,
  obtenerUsuario,
  actualizarUsuario,
  eliminarUsuario,
  obtenerUsuariosFiltrado,
  obtenerProyectos,
  llenarComboUsuarios,
  validar_carga_usuario,
  CargaMasivaUsuario_mantenimiento,
  


  obtenerTipoIdentificacion,
  validarTipoIdentificacion,
  crearTipoIdentificacion,
  actualizarTipoIdentificacion,
  eliminarTipoIdentificacion,
  listarTipoIdentificacionCombo,

  /* Banco Ifis*/
  listarBancoIfis,
  registroBancoifis,
  editarBancoIfis,
  eliminarBancoIfis,
  validarBancoIfis,

  /* Banco Compañias*/
  listarBancoCompanias,
  registroBancoCompanias,
  editarBancoCompanias,
  eliminarBancoCompanias,
  validarBancoCompanias,

  /* Tipo Vehiculo*/
  listarTipoVehiculo,
  registroTipoVehiculo,
  actualizarTipoVehiculo,
  eliminarTipoVehiculo,
  validacionTipoVehiculo,

  /* Area */
  getArea,
  createArea,
  //editArea,
  updateArea,
  deleteArea,
  ComboAreaClientes,

  /* Departamento */
  getDepartamento,
  createDepartamento,
  //editDepartamento,
  selectAreas,
  updateDepartamento,
  deleteDepartamento,

  listarFormaPago,
  crearFormaPago,
  actualizarFormaPago,
  eliminarFormaPago,
  validarFormaPago,

  listarReferenciaPago,
  crearReferenciaPago,
  actualizarReferenciaPago,
  eliminarReferenciaPago,
  validarReferenciaPago,

  listarSubMenu,
  crearSubMenu,
  actualizarSubMenu,
  eliminarSubMenu,
  validarnameSubMenu,
  llenarComboSubMenu,

  listarPerfil,
  crearPerfil,
  actualizarPerfil,
  eliminarPerfil,
  validardescPerfil,
  llenarComboPerfil,
  llenarComboCargoPorPerfil,
  listarPerfilPermisos,
  obtenerPermisosPerfil,
  obtenerPermisosPerfilEspeciales,
  guardarPerfilPermisos,
  guardarPermisosPerfilEspeciales,
  obtenerPermisosUsuario,
  obtenerPermisosUsuarioEspeciales,
  guardarUsuarioPermiso,
  guardarPermisosUsuarioEspeciales,
  listarPermisosEspeciales,

  listarCargo,
  validarCargo,
  crearCargo,
  actualizarCargo,
  eliminarCargo,

  //Estado civil
  listarEstadoCivil,
  crearEstadoCivil,
  actualizarEstadoCivil,
  eliminarEstadoCivil,
  validarEstadoCivil,
  select_EstadoCivil,

  listarNacionalidad,
  validarNacionalidad,
  crearNacionalidad,
  actualizarNacionalidad,
  eliminarNacionalidad,

  listarNivelAcademico,
  validarNivelAcademico,
  crearNivelAcademico,
  actualizarNivelAcademico,
  eliminarNivelAcademico,

  listarTipoCliente,
  validarTipoCliente,
  crearTipoCliente,
  actualizarTipoCliente,
  eliminarTipoCliente,
  selectTipoCliente,

  listarTipoPersona,
  validarTipoPersona,
  crearTipoPersona,
  actualizarTipoPersona,
  eliminarTipoPersona,
  selectTipoPersona,

  //Parentesco
  listarParentesco,
  crearParentesco,
  actualizarParentesco,
  eliminarParentesco,
  validarParentesco,

  listarMotivosEliminacion,
  validarMotivosEliminacion,
  crearMotivosEliminacion,
  actualizarMotivosEliminacion,
  eliminarMotivosEliminacion,

  listarPermisos,
  crearPermiso,
  actualizarPermiso,
  eliminarPermiso,
  validarPermiso,

  listarAccion,
  crearAccion,
  actualizarAccion,
  eliminarAccion,
  validardescAccion,
  llenarComboAccion,

  /* Marca */
  listarMarca,
  registroMarca,
  actualizarMarca,
  eliminarMarca,
  validacionMarca,

  /* Vehiculo */
  listarVehiculo,
  registroVehiculo,
  actualizarVehiculo,
  eliminarVehiculo,
  validacionVehiculo,
  llenarComboTipoVehiculo,
  llenarComboMarca,

  listarMotivosReactivacion,
  validarMotivosReactivacion,
  crearMotivosReactivacion,
  actualizarMotivosReactivacion,
  eliminarMotivosReactivacion,

  listarMotivosLiberacion,
  validarMotivosLiberacion,
  crearMotivosLiberacion,
  actualizarMotivosLiberacion,
  eliminarMotivosLiberacion,
  //Tipo Bienes Mobiliario
  listarBienesMobiliario,
  crearBienesMobiliario,
  actualizarBienesMobiliario,
  eliminarBienesMobiliario,
  validarBienesMobiliario,

  //empresa
  listarEmpresa,
  crearEmpresa,
  actualizarEmpresa,
  eliminarEmpresa,
  listarCanal,
  validarCanal,
  crearCanal,
  actualizarCanal,
  eliminarCanal,
  combotipoempresa,

  listarSubCanal,
  validarSubCanal,
  crearSubCanal,
  actualizarSubCanal,
  eliminarSubCanal,
  selectCanales,
  selectSubcanal,

  //Motivos Bloqueo Desbloqueo
  listarBloqueoDesbloqueo,
  crearBloqueoDesbloqueo,
  actualizarBloqueoDesbloqueo,
  eliminarBloqueoDesbloqueo,
  validarBloqueoDesbloqueo,
  /**
   * Empresa Proyecto
   */
  listarComboEmpresa,
  listarComboPrestadores,
  listarEmpresaPrestadores,
  crearEmpresaPrestador,
  actualizarEmpresaPrestador,
  eliminarEmpresaPrestador,
  selectPrestador,

  //Locales Comerciales
  listarLocalesComerciales,
  registroLocalesComerciales,
  actualizarLocalComercial,
  eliminarLocalComercial,
  validacionLocalComercial,

  //Relacion Laboral
  listarRelacionLaboral,
  registroRelacionLaboral,
  actualizarRelacionLaboral,
  eliminarRelacionLaboral,
  validacionRelacionLaboral,

  //Tipo de vivienda
  listarTipoVivienda,
  crearTipoVivienda,
  actualizarTipoVivienda,
  eliminarTipoVivienda,
  validarTipoVivienda,
  selectTipoVivienda,

  //Actividad de la empresa
  listarActividadEmpresa,
  crearActividadEmpresa,
  actualizarActividadEmpresa,
  eliminarActividadEmpresa,
  validarActividadEmpresa,

  //Tipo de cuenta
  listarTipoCuenta,
  crearTipoCuenta,
  actualizarTipoCuenta,
  eliminarTipoCuenta,
  //validarTipoCuenta
  validarTipoCuenta,

  //Cargo Laboral
  listarCargoLaboral,
  crearCargoLaboral,
  actualizarCargoLaboral,
  eliminarCargoLaboral,
  validacionCargoLaboral,
  comboRelacionLaboral,

  //Cuenta bancaria compania
  listarCuentaBancaria,
  crearCuentaBancaria,
  actualizarCuentaBancaria,
  eliminarCuentaBancaria,
  validarCuentaBancaria,
  listarTiposCuentas,
  listarBancoCompania,

  listarCuentaByCompania,

  listarMedios,
  selectMedios,
  validarMedios,
  crearMedios,
  actualizarMedios,
  eliminarMedios,

  listarSubMedios,
  validarSubMedios,
  crearSubMedios,
  actualizarSubMedios,
  eliminarSubMedios,
  selectSubmedios,

  listarTipoPagoTarjetas,
  validarTipoPagoTarjetas,
  crearTipoPagoTarjetas,
  actualizarTipoPagoTarjetas,
  eliminarTipoPagoTarjetas,

  listarEmisorTarjetas,
  selectEmisorTarjetas,
  validarEmisorTarjetas,
  crearEmisorTarjetas,
  actualizarEmisorTarjetas,
  eliminarEmisorTarjetas,

  /**
   * SECTORIZACION
   **/
  //Cuadrante
  listarCuadrante,
  crearCuadrante,
  actualizarCuadrante,
  eliminarCuadrante,
  validarCuadrante,
  selectPais,
  selectProvincia,
  selectCiudad,
  selectCuadrante,

  //Parroquia
  listarParroquia,
  crearParroquia,
  actualizarParroquia,
  eliminarParroquia,
  validarParroquia,
  selectParroquia,

  //Contratista
  listarContratista,
  obtenerContratistaFiltrado,
  crearContratista,
  actualizarContratista,
  eliminarContratista,
  validarContratista,

  //Sector
  listarSector,
  crearSector,
  actualizarSector,
  eliminarSector,
  validarSector,

  listarTarjetaCredito,
  validarTarjetaCredito,
  crearTarjetaCredito,
  actualizarTarjetaCredito,
  eliminarTarjetaCredito,

  //Prestador
  llenarComboPrestador,

  //Tipo Proyecto
  obtenerTipoProyecto,
  validarTipoProyecto,
  crearTipoProyecto,
  actualizarTipoProyecto,
  eliminarTipoProyecto,
  listarTipoProyectoCombo,
  //Tipo Asignacion
  listarTipoAsignacion,
  crearTipoAsignacion,
  actualizarTipoAsignacion,
  eliminarTipoAsignacion,
  validarTipoAsignacion,

  /**
   * Acceso Perfil Defecto
   */

  listarPerfilAcceso,
  registrarPerfilAcceso,
  actualizarPerfilAcceso,
  eliminarPerfilController,
  validarPerfilController,
  llenarcomboPerfil,
  llenarComboSubMenuPerfil,
  obtenerPerfilFiltrado,

  //Tipo Campo
  listarTipoCampo,
  crearTipoCampo,
  actualizarTipoCampo,
  eliminarTipoCampo,
  validarTipoCampo,

  //Parametros
  listarParametros,
  crearParametros,
  actualizarParametros,
  eliminarParametros,
  validarParametros,
  listarTiposCampos,

  // Politicas
  listarPolitica,
  selectEtapa,
  selectManzana,
  crearFormulario,
  /*crearPolitica,
  actualizarPolitica,
  eliminarPolitica,
  validarPolitica,*/

  /**Producto */
  listarProductos,
  crearProducto,
  actualizarProducto,
  eliminarProducto,
  ValidarCargaProducto,
  CargaMasivaEstadoProducto,

  /*FUENTES*/
  getFuentes,
  createFuentes,
  actualizarFuentes,
  eliminarFuentes,

  /*tipo gastos*/
  getTipoGastos,
  createTipoGastos,
  actualizarTipoGastos,
  eliminarTipoGastos,

  /*planes*/
  getPlanes,
  createPlanes,
  actualizarPlanes,
  eliminarPlanes,

  /*plazos*/
  getPlazos,
  createPlazos,
  actualizarPlazos,
  eliminarPlazos,

  /*prestadores*/
  getPrestadores,
  createPrestadores,
  actualizarPrestadores,
  eliminarPrestadores,

  /*planes por prestadores*/
  getPlanPrestador,
  createPlanPrestador,
  registroPlanPrestador,
  actualizarPlanPrestador,
  eliminarPlanPrestador,
  comboPlanes,
  comboPlazos,
  comboPrestadores,
  validacionPlanPrestador,
  valorMeta,

  /*planes por procesos*/
  listarProcesos,
  llenarComboFuentes,
  crearProceso,
  actualizarProceso,
  eliminarProceso,
  ejecutarProceso,

  /*metodos tipo documentos*/
  listarTipoDocumento,
  validarTipoDocumento,
  CrearTipoDocumento,
  actualizarTipoDocumento,
  EliminarTipoDocumento,
  listarcomboTipoPersona,
  ContratoClientePlanes,

  crearCabCargafamiliar,
  obtenerdiscapacidadclientes,
  creardiscapacidadCliente,
  eliminardiscapacidadCliente,

  obtenerviviendasclientes,
  crearvehiculoCliente,
  eliminarVehiculoCliente,
  crearViviendaCliente,
  eliminarViviendaCliente,
  obtenervehiculosclientes,
  obtenerClientes,
  combos_cliente,
  //Discapacidad
  ObtenerDiscapacidad,
  eliminarDiscapacidad,
  crearDiscapacidad,
  actualizarDiscapacidad,
  //Actualizar Prospectos clientes
  actualizarCliente,
  eliminar_cliente,
  // CRUD Carga Familiar Cliente
  clientecargafamiliar,
  obtenerFamiliaresCliente,
  eliminar_clientecargafamiliar,
  actualizar_clientecargafamiliar,
  crear_clientecargafamiliar,
  // CRUD Cuenta Bancaria Cliente
  clientecuentabancaria,
  registrar_clientecuentabancaria,
  actualizar_clientecuentabancaria,
  eliminar_clientecuentabancaria,

  obtenerClienteFiltrado,
  obtenerClienteEspecifico,

  //Ingreso cliente
  obtenerClienteIngresos,
  crearClienteIngreso,
  eliminarClienteIngreso,
  //gasto Cliente
  obtenerClienteGastos,
  crearClienteGasto,
  eliminarClienteGasto,
  //activos cliente
  obtenerClienteActivo,
  crearClienteActivo,
  eliminarClienteActivo,
  //pasivos cliente
  obtenerClientePasivo,
  crearClientePasivo,
  eliminarClientePasivo,
  //combo ComboLaboralCargoClientes
  ComboLaboralCargoClientes,

  obtenerClienteInformacionPDF,

  /*tipo familiar*/
  getTipoFamiliar,
  createTipoFamiliar,
  eliminarTipoFamiliar,
  actualizarTipoFamiliar,

  /*tipo negocios*/
  listarTipoNegocios,
  crearTipoNegocio,
  eliminarTipoNegocio,
  actualizarTipoNegocios,
  validarTipoNegocio,

  /* Obtener Lugares */
  obtenerProvincia,
  obtenerCiudad,
  obtenerPais,
  obtenerProvinciaPais,
  /* Registro documentos */
  RegistroDocumentos,
  bajarArchivo,
  AsignarRiesgo,
  obtenerClientes_AnalistaRiesgo,
  filtrarClientes_AnalistaRiesgo,
  comboTipoDocumento,

  /*metodos para la clasificacion de documentos*/
  listarComboTipoPersona,
  EliminarClasificacionDocumento,
  actualizarClasificacionDocumento,
  CrearClasificacionDocumento,
  validarClasificacionDocumento,
  listarClasificacionDocumento,
  listarClasificaciones,
  listarClasificacionesPorPersona,
  PlanesInteresado,
  /* Flujo x Prestador */
  ListarFlujoXPrestador,
  EditarFlujoXPrestador,
  CrearFlujoXPrestador,
  EliminarFlujoXPrestador,
  FiltrarFlujoXPrestador,
  ListarActividadesXFlujo,

  listarEmpresaProyecto,
  listarComboProyecto,

  obtenerUsuarioSesion,
  obtenerProveedorSesion,
  actualizarInfPUsuario,
  actualizarProveedor,
  actualizarPasswordUsuario,
  listadoDatosFacturacionUsuario,
  crearCuentaFormulario,
  obtenerUsuariosIdentificacion,
};

function listarComboProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}empresaproyecto/listarComboProyecto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarEmpresaProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}empresaproyecto/listarEmpresaProyecto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Relacion Laboral
function listarRelacionLaboral(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}relacionlaboral/listarRelacionLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroRelacionLaboral(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}relacionlaboral/registroRelacionLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarRelacionLaboral(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}relacionlaboral/actualizarRelacionLaboral`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarRelacionLaboral(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}relacionlaboral/eliminarRelacionLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validacionRelacionLaboral(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}relacionlaboral/validacionRelacionLaboral`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Locales Comerciales
function listarLocalesComerciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(
    `${Url}localescomerciales/listarLocalesComerciales`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroLocalesComerciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}localescomerciales/registroLocalesComerciales`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarLocalComercial(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}localescomerciales/actualizarLocalComercial`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarLocalComercial(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}localescomerciales/eliminarLocalComercial`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validacionLocalComercial(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}localescomerciales/validacionLocalComercial`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Vehiculo
function listarVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}vehiculo/listarVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/registroVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/actualizarVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarVehiculo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/eliminarVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validacionVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}vehiculo/validacionVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboTipoVehiculo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/llenarComboTipoVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboMarca(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/llenarComboMarca`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Marca

function listarMarca(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}vehiculo/listarMarca`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroMarca(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/registroMarca`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarMarca(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/actualizarMarca`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarMarca(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/eliminarMarca`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validacionMarca(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}vehiculo/validacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Banco Ifis

function listarBancoIfis(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}bancos/banco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroBancoifis(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bancos/registrobancoifis`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function editarBancoIfis(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bancos/editarbancoifis`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarBancoIfis(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bancos/eliminarbancoifis`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarBancoIfis(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}bancos/validarbancoifis`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

// Banco Companias

function listarBancoCompanias(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}bancos/bancoCompania`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroBancoCompanias(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bancos/registrobancocompanias`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function editarBancoCompanias(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bancos/editarbancocompanias`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarBancoCompanias(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bancos/eliminarbancocompanias`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function llenarComboSubMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}submenu/llenarComboSubMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarBancoCompanias(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}bancos/validar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/**
 * Tipo de
 * Vehiculo
 */

function listarTipoVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}vehiculo/listarTipoVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroTipoVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/registroTipoVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarTipoVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/actualizarTipoVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarTipoVehiculo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}vehiculo/eliminarTipoVehiculo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validacionTipoVehiculo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}vehiculo/validar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//---------------Permiso--------------------
function listarPermisos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}permiso/listarPermisos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarPermiso(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}permiso/validarPermiso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearPermiso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}permiso/crearPermiso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPermiso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}permiso/actualizarPermiso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarPermiso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}permiso/eliminarPermiso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//---------------Fin Permiso--------------------

//---------------Accion--------------------
function listarAccion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}accion/listarAccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validardescAccion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}accion/validardescAccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearAccion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}accion/crearAccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarAccion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}accion/actualizarAccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarAccion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}accion/eliminarAccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboAccion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}accion/llenarComboAccion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//---------------Fin Accion--------------------

//---------------Menu--------------------
function listarMenu(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}menu/listarMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarnameMenu(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}menu/validarnameMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}menu/crearMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}menu/actualizarMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}menu/eliminarMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}menu/llenarComboMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//---------------Fin Menu--------------------

//---------------SubMenu--------------------
function listarSubMenu(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}submenu/listarSubMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarnameSubMenu(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}submenu/validarnameSubMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearSubMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}submenu/crearSubMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarSubMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}submenu/actualizarSubMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarSubMenu(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}submenu/eliminarSubMenu`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//---------------Fin SubMenu--------------------

//---------------Perfil--------------------
function listarPerfil(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}perfil/listarPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validardescPerfil(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfil/validardescPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearPerfil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}perfil/crearPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPerfil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}perfil/actualizarPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarPerfil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}perfil/eliminarPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboPerfil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}perfil/llenarComboPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboCargoPorPerfil(data) {
  debugger;
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ idPerfil: data }),
  };

  return fetch(`${Url}perfil/llenarComboCargoPorPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//---------------Fin Perfil--------------------

//---------------PerfilPermisos--------------------
function listarPerfilPermisos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}perfilpermiso/listarPerfilPermisos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerPermisosPerfil(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfilpermiso/obtenerPermisosPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerPermisosPerfilEspeciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}perfilpermiso/obtenerPermisosPerfilEspeciales`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardarPerfilPermisos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfilpermiso/guardarPerfilPermisos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardarPermisosPerfilEspeciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}perfilpermiso/guardarPermisosPerfilEspeciales`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarPermisosEspeciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfilpermiso/listarPermisosEspeciales`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//---------------Fin PerfilPermisos--------------------

//---------------UsuarioPermisos--------------------
function obtenerPermisosUsuario(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}usuariopermiso/obtenerPermisosUsuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardarUsuarioPermiso(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}usuariopermiso/guardarUsuarioPermiso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerPermisosUsuarioEspeciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}usuariopermiso/obtenerPermisosUsuarioEspeciales`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function guardarPermisosUsuarioEspeciales(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}usuariopermiso/guardarPermisosUsuarioEspeciales`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//---------------Fin UsuarioPermisos--------------------

//Comunicaciones
function listarSubmenu() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}comunicacion/listarSubmenu`, requestOptions).then(
    HandleResponse
  );
}

function listarCodigoStatus() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}comunicacion/listarCodigoStatus`, requestOptions).then(
    HandleResponse
  );
}

function listarTipoAlerta() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}comunicacion/listarTipoAlerta`, requestOptions).then(
    HandleResponse
  );
}
function obtenerAlertasFiltrado(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/obtenerAlertasFiltrado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function getAlertas(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}comunicacion/getAlertas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function crearAlertas(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/crearAlertas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarAlerta(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/actualizarAlerta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarAlerta(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/eliminarAlerta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Usuario
function listarTipo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}comunicacion/listarTipo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearTipo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/crearTipo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarTipo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/actualizarTipo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function listarTipoIdentificacionCombo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(
    `${Url}tipoidentificacion/listarTipoIdentificacionCombo`,
    requestOptions
  ).then(HandleResponse);
}

function obtenerTipoIdentificacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}tipoidentificacion/listarTipoIdentificacion`,
    requestOptions
  ).then(HandleResponse);
}

function validarTipoIdentificacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipoidentificacion/validarTipoIdentificacion`,
    requestOptions
  ).then(HandleResponse);
}

function crearTipoIdentificacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipoidentificacion/crearTipoIdentificacion`,
    requestOptions
  ).then(HandleResponse);
}
function actualizarTipoIdentificacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipoidentificacion/actualizarTipoIdentificacion`,
    requestOptions
  ).then(HandleResponse);
}
function eliminarTipoIdentificacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipoidentificacion/eliminarTipoIdentificacion`,
    requestOptions
  ).then(HandleResponse);
}

function actualizarUsuario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}usuario/actualizarUsuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarTipo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/eliminarTipo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarStatus(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}comunicacion/listarStatus`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearStatus(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/crearStatus`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarStatus(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/actualizarStatus`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarStatus(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}comunicacion/eliminarStatus`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerUsuarios() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}usuario/obtenerUsuarios`, requestOptions).then(
    HandleResponse
  );
}

function obtenerUsuario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}usuario/obtenerUsuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerProyectos() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}usuario/obtenerProyectosEmpresa`, requestOptions).then(
    HandleResponse
  );
}

function eliminarUsuario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}usuario/eliminarUsuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboUsuarios(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}usuario/llenarComboUsuarios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*
 * Mantenimiento de Área
 */
function getArea(data) {
  //const requestOptions = { method: "GET", headers: AuthHeader() };
  //return fetch(`${Url}area/getArea`, requestOptions).then(HandleResponse);
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}area/getArea`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function ComboAreaClientes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}area/ComboAreaClientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function createArea(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}area/createArea`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectAreas() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}area/selectAreas`, requestOptions).then(HandleResponse);
}

function updateArea(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}area/updateArea`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerUsuariosFiltrado(data) {
  debugger;
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}usuario/obtenerUsuariosFiltrado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearUsuario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}usuario/crearUsuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function deleteArea(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}area/deleteArea`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*
 * Mantenimiento de Departamento
 */
function getDepartamento(data) {
  //const requestOptions = { method: "GET", headers: AuthHeader() };
  //return fetch(`${Url}departamento/getDepartamento`, requestOptions).then(HandleResponse);
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}departamento/getDepartamento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createDepartamento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}departamento/createDepartamento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function updateDepartamento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}departamento/updateDepartamento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function deleteDepartamento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}departamento/deleteDepartamento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarReferenciaPago(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}pago/listarReferenciaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearReferenciaPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}pago/crearReferenciaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarReferenciaPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}pago/actualizarReferenciaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarReferenciaPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}pago/eliminarReferenciaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarReferenciaPago(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}pago/validarReferenciaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarFormaPago(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}pago/listarFormaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearFormaPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}pago/crearFormaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarFormaPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}pago/actualizarFormaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarFormaPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}pago/eliminarFormaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarFormaPago(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}pago/validarFormaPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarCargo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}cargo/listarCargo`, requestOptions).then(HandleResponse);
}

function validarCargo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cargo/validarCargo`, requestOptions).then(HandleResponse);
}

function crearCargo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cargo/crearCargo`, requestOptions).then(HandleResponse);
}

function actualizarCargo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cargo/actualizarCargo`, requestOptions).then(
    HandleResponse
  );
}

function eliminarCargo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cargo/eliminarCargo`, requestOptions).then(
    HandleResponse
  );
}

function listarEstadoCivil(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}estadocivil/listarEstadoCivil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearEstadoCivil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}estadocivil/crearEstadoCivil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarEstadoCivil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}estadocivil/actualizarEstadoCivil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarEstadoCivil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}estadocivil/eliminarEstadoCivil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarEstadoCivil(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}estadocivil/validarEstadoCivil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function select_EstadoCivil() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}estadocivil/selectEstadoCivil`, requestOptions).then(
    HandleResponse
  );
}

function listarNacionalidad(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}nacionalidad/listarNacionalidad`, requestOptions).then(
    HandleResponse
  );
}

function validarNacionalidad(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}nacionalidad/validarNacionalidad`, requestOptions).then(
    HandleResponse
  );
}

function crearNacionalidad(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}nacionalidad/crearNacionalidad`, requestOptions).then(
    HandleResponse
  );
}

function actualizarNacionalidad(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}nacionalidad/actualizarNacionalidad`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarNacionalidad(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}nacionalidad/eliminarNacionalidad`, requestOptions).then(
    HandleResponse
  );
}

function listarNivelAcademico(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}nivelacademico/listarNivelAcademico`,
    requestOptions
  ).then(HandleResponse);
}

function validarNivelAcademico(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}nivelacademico/validarNivelAcademico`,
    requestOptions
  ).then(HandleResponse);
}

function crearNivelAcademico(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}nivelacademico/crearNivelAcademico`, requestOptions).then(
    HandleResponse
  );
}

function actualizarNivelAcademico(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}nivelacademico/actualizarNivelAcademico`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarNivelAcademico(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}nivelacademico/eliminarNivelAcademico`,
    requestOptions
  ).then(HandleResponse);
}

function listarTipoCliente(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipocliente/listarTipoCliente`, requestOptions).then(
    HandleResponse
  );
}

function validarTipoCliente(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipocliente/validarTipoCliente`, requestOptions).then(
    HandleResponse
  );
}

function crearTipoCliente(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipocliente/crearTipoCliente`, requestOptions).then(
    HandleResponse
  );
}

function actualizarTipoCliente(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipocliente/actualizarTipoCliente`, requestOptions).then(
    HandleResponse
  );
}

function eliminarTipoCliente(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipocliente/eliminarTipoCliente`, requestOptions).then(
    HandleResponse
  );
}

function selectTipoCliente() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tipocliente/selectTipoCliente`, requestOptions).then(
    HandleResponse
  );
}

function listarTipoPersona(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipopersona/listarTipoPersona`, requestOptions).then(
    HandleResponse
  );
}

function validarTipoPersona(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipopersona/validarTipoPersona`, requestOptions).then(
    HandleResponse
  );
}

function crearTipoPersona(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipopersona/crearTipoPersona`, requestOptions).then(
    HandleResponse
  );
}

function actualizarTipoPersona(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipopersona/actualizarTipoPersona`, requestOptions).then(
    HandleResponse
  );
}

function eliminarTipoPersona(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipopersona/eliminarTipoPersona`, requestOptions).then(
    HandleResponse
  );
}

function selectTipoPersona() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(`${Url}tipopersona/selectTipoPersona`, requestOptions).then(
    HandleResponse
  );
}

function listarParentesco(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}parentesco/listarParentesco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearParentesco(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parentesco/crearParentesco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarParentesco(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parentesco/actualizarParentesco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarParentesco(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parentesco/eliminarParentesco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarParentesco(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}parentesco/validarParentesco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarMotivosEliminacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}motivoseliminacion/listarMotivosEliminacion`,
    requestOptions
  ).then(HandleResponse);
}

function validarMotivosEliminacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivoseliminacion/validarMotivosEliminacion`,
    requestOptions
  ).then(HandleResponse);
}

function crearMotivosEliminacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivoseliminacion/crearMotivosEliminacion`,
    requestOptions
  ).then(HandleResponse);
}

function actualizarMotivosEliminacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivoseliminacion/actualizarMotivosEliminacion`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarMotivosEliminacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivoseliminacion/eliminarMotivosEliminacion`,
    requestOptions
  ).then(HandleResponse);
}

function listarMotivosReactivacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}motivosreactivacion/listarMotivosReactivacion`,
    requestOptions
  ).then(HandleResponse);
}

function validarMotivosReactivacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosreactivacion/validarMotivosReactivacion`,
    requestOptions
  ).then(HandleResponse);
}

function crearMotivosReactivacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosreactivacion/crearMotivosReactivacion`,
    requestOptions
  ).then(HandleResponse);
}

function actualizarMotivosReactivacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosreactivacion/actualizarMotivosReactivacion`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarMotivosReactivacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosreactivacion/eliminarMotivosReactivacion`,
    requestOptions
  ).then(HandleResponse);
}

function listarMotivosLiberacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}motivosliberacion/listarMotivosLiberacion`,
    requestOptions
  ).then(HandleResponse);
}

function validarMotivosLiberacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosliberacion/validarMotivosLiberacion`,
    requestOptions
  ).then(HandleResponse);
}

function crearMotivosLiberacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosliberacion/crearMotivosLiberacion`,
    requestOptions
  ).then(HandleResponse);
}

function actualizarMotivosLiberacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosliberacion/actualizarMotivosLiberacion`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarMotivosLiberacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}motivosliberacion/eliminarMotivosLiberacion`,
    requestOptions
  ).then(HandleResponse);
}
function listarBienesMobiliario(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}bienesmobiliarios/listarBienesMobiliario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearBienesMobiliario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bienesmobiliarios/crearBienesMobiliario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarBienesMobiliario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}bienesmobiliarios/actualizarBienesMobiliario`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarBienesMobiliario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}bienesmobiliarios/eliminarBienesMobiliario`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarBienesMobiliario(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}bienesmobiliarios/validarBienesMobiliario`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//*******Empresa************
function listarEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}empresa/listarEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  
  

  return fetch(`${Url}empresa/crearEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function combotipoempresa(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}empresa/combotipoempresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });


}
  

function actualizarEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}empresa/actualizarEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}empresa/eliminarEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//******Fin*******
function listarCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}canal/listarCanal`, requestOptions).then(HandleResponse);
}

function selectCanales() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}canal/selectCanales`, requestOptions).then(
    HandleResponse
  );
}

function validarCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}canal/validarCanal`, requestOptions).then(HandleResponse);
}

function crearCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}canal/crearCanal`, requestOptions).then(HandleResponse);
}

function actualizarCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}canal/actualizarCanal`, requestOptions).then(
    HandleResponse
  );
}

function eliminarCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}canal/eliminarCanal`, requestOptions).then(
    HandleResponse
  );
}

function listarSubCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}subcanal/listarSubCanal`, requestOptions).then(
    HandleResponse
  );
}

function validarSubCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}subcanal/validarSubCanal`, requestOptions).then(
    HandleResponse
  );
}

function crearSubCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}subcanal/crearSubCanal`, requestOptions).then(
    HandleResponse
  );
}

function actualizarSubCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}subcanal/actualizarSubCanal`, requestOptions).then(
    HandleResponse
  );
}

function eliminarSubCanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}subcanal/eliminarSubCanal`, requestOptions).then(
    HandleResponse
  );
}

function selectSubcanal(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}subcanal/selectSubcanal`, requestOptions).then(
    HandleResponse
  );
}

function listarBloqueoDesbloqueo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}bloqueodesbloqueo/listarBloqueoDesbloqueo`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearBloqueoDesbloqueo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}bloqueodesbloqueo/crearBloqueoDesbloqueo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarBloqueoDesbloqueo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}bloqueodesbloqueo/actualizarBloqueoDesbloqueo`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarBloqueoDesbloqueo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}bloqueodesbloqueo/eliminarBloqueoDesbloqueo`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarBloqueoDesbloqueo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}bloqueodesbloqueo/validarBloqueoDesbloqueo`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarTipoVivienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipovivienda/listarTipoVivienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function selectTipoVivienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipovivienda/selectTipoVivienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearTipoVivienda(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipovivienda/crearTipoVivienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarTipoVivienda(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipovivienda/actualizarTipoVivienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarTipoVivienda(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipovivienda/eliminarTipoVivienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarTipoVivienda(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipovivienda/validarTipoVivienda`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarActividadEmpresa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}actividadempresa/listarActividadEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearActividadEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}actividadempresa/crearActividadEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarActividadEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}actividadempresa/actualizarActividadEmpresa`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarActividadEmpresa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}actividadempresa/eliminarActividadEmpresa`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarActividadEmpresa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}actividadempresa/validarActividadEmpresa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarTipoCuenta(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipocuenta/listarTipoCuenta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearTipoCuenta(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipocuenta/crearTipoCuenta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarTipoCuenta(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipocuenta/actualizarTipoCuenta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarTipoCuenta(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipocuenta/eliminarTipoCuenta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarTipoCuenta(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipocuenta/validarTipoCuenta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//------------Cargo Laboral-----------
//Cuentas Bancarias companias
function listarTiposCuentas() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}cuentasbancarias/listarTipoCuenta`, requestOptions).then(
    HandleResponse
  );
}

function listarBancoCompania() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(
    `${Url}cuentasbancarias/listarBancoCompania`,
    requestOptions
  ).then(HandleResponse);
}

function listarCuentaBancaria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}cuentasbancarias/listarCuentaBancaria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function listarCuentaByCompania(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}cuentasbancarias/getByCompania`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearCuentaBancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuentasbancarias/crearCuentaBancaria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarCuentaBancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}cuentasbancarias/actualizarCuentaBancaria`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarCuentaBancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuentasbancarias/eliminarCuentaBancaria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarCuentaBancaria(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cuentasbancarias/validarCuentaBancaria`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}medios/listarMedios`, requestOptions).then(
    HandleResponse
  );
}

function selectMedios() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}medios/selectMedios`, requestOptions).then(
    HandleResponse
  );
}

function validarMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}medios/validarMedios`, requestOptions).then(
    HandleResponse
  );
}

function crearMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}medios/crearMedios`, requestOptions).then(HandleResponse);
}

function actualizarMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}medios/actualizarMedios`, requestOptions).then(
    HandleResponse
  );
}

function eliminarMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}medios/eliminarMedios`, requestOptions).then(
    HandleResponse
  );
}

function listarSubMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}submedios/listarSubMedios`, requestOptions).then(
    HandleResponse
  );
}

function validarSubMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}submedios/validarSubMedios`, requestOptions).then(
    HandleResponse
  );
}

function crearSubMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}submedios/crearSubMedios`, requestOptions).then(
    HandleResponse
  );
}

function actualizarSubMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}submedios/actualizarSubMedios`, requestOptions).then(
    HandleResponse
  );
}

function eliminarSubMedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}submedios/eliminarSubMedios`, requestOptions).then(
    HandleResponse
  );
}

function selectSubmedios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}submedios/selectSubmedios`, requestOptions).then(
    HandleResponse
  );
}
// Listar Cargo Laboral

function listarCargoLaboral(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  }; //estado:data
  return fetch(`${Url}cargolaboral/listarCargoLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearCargoLaboral(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cargolaboral/crearCargoLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarCargoLaboral(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cargolaboral/actualizarCargoLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarCargoLaboral(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cargolaboral/eliminarCargoLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validacionCargoLaboral(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cargolaboral/validacionCargoLaboral`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function comboRelacionLaboral() {
  const requestOptions = { method: "POST", headers: AuthHeader() };
  return fetch(
    `${Url}relacionlaboral/comboRelacionLaboral`,
    requestOptions
  ).then(HandleResponse);
}

/**
 * Empresa Prestadores
 */
function listarComboEmpresa(filtro = "all") {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(
    `${Url}empresaproyecto/listarComboEmpresa?filtro=${filtro}`,
    requestOptions
  ).then(HandleResponse);
}

function listarComboPrestadores() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(
    `${Url}empresaprestador/listarComboPrestador`,
    requestOptions
  ).then(HandleResponse);
}

function listarEmpresaPrestadores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}empresaprestador/listarEmpresaPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearEmpresaPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}empresaprestador/crearEmpresaPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarEmpresaPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}empresaprestador/actualizarEmpresaPrestador`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarEmpresaPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}empresaprestador/eliminarEmpresaPrestador`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectPrestador() {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
  };

  return fetch(`${Url}empresaprestador/selectPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*pago de tarjetas*/
function listarTipoPagoTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}tipopagotarjetas/listarTipoPagoTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function validarTipoPagoTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipopagotarjetas/validarTipoPagoTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function crearTipoPagoTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipopagotarjetas/crearTipoPagoTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function actualizarTipoPagoTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipopagotarjetas/actualizarTipoPagoTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarTipoPagoTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipopagotarjetas/eliminarTipoPagoTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function listarEmisorTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}emisortarjetas/listarEmisorTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function selectEmisorTarjetas() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(
    `${Url}emisortarjetas/selectEmisorTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function validarEmisorTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}emisortarjetas/validarEmisorTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function crearEmisorTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}emisortarjetas/crearEmisorTarjetas`, requestOptions).then(
    HandleResponse
  );
}

function actualizarEmisorTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}emisortarjetas/actualizarEmisorTarjetas`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarEmisorTarjetas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}emisortarjetas/eliminarEmisorTarjetas`,
    requestOptions
  ).then(HandleResponse);
}
/**
 * FIn
 */

/*--------- CUADRANTE ---------*/
function listarCuadrante(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}cuadrante/listarCuadrante`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarCuadrante(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cuadrante/validarCuadrante`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearCuadrante(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuadrante/crearCuadrante`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarCuadrante(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuadrante/actualizarCuadrante`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarCuadrante(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuadrante/eliminarCuadrante`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectPais() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}cuadrante/selectPais`, requestOptions).then(
    HandleResponse
  );
}

function selectProvincia(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuadrante/selectProvincia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectCiudad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuadrante/selectCiudad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectCuadrante(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}cuadrante/selectCuadrante`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Contratista
function listarContratista() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}contratista/listarContratista`, requestOptions).then(
    HandleResponse
  );
}

function obtenerContratistaFiltrado(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}contratista/obtenerContratistaFiltrado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearContratista(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}contratista/crearContratista`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarContratista(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}contratista/actualizarContratista`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarContratista(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}contratista/eliminarContratista`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarContratista(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}contratista/validarContratista`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*--------- Parroquia ---------*/
function listarParroquia(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}parroquia/listarParroquia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarParroquia(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}parroquia/validarParroquia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearParroquia(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parroquia/crearParroquia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarParroquia(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parroquia/actualizarParroquia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarParroquia(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parroquia/eliminarParroquia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*--------- Sector ---------*/
function listarSector(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}sector/listarSector`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectParroquia() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}parroquia/selectParroquia`, requestOptions).then(
    HandleResponse
  );
}

function validarSector(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}sector/validarSector`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearSector(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}sector/crearSector`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarSector(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}sector/actualizarSector`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarSector(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}sector/eliminarSector`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarTarjetaCredito(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}tarjetacredito/listarTarjetaCredito`,
    requestOptions
  ).then(HandleResponse);
}

function validarTarjetaCredito(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tarjetacredito/validarTarjetaCredito`,
    requestOptions
  ).then(HandleResponse);
}

function crearTarjetaCredito(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tarjetacredito/crearTarjetaCredito`, requestOptions).then(
    HandleResponse
  );
}

function actualizarTarjetaCredito(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tarjetacredito/actualizarTarjetaCredito`,
    requestOptions
  ).then(HandleResponse);
}

function eliminarTarjetaCredito(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tarjetacredito/eliminarTarjetaCredito`,
    requestOptions
  ).then(HandleResponse);
}

//prestador
function llenarComboPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}prestadores/llenarComboPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Tipo Asignacion
function listarTipoAsignacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipoasignacion/listarTipoAsignacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearTipoAsignacion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipoasignacion/crearTipoAsignacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarTipoAsignacion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipoasignacion/actualizarTipoAsignacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarTipoAsignacion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipoasignacion/eliminarTipoAsignacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarTipoAsignacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipoasignacion/validarTipoAsignacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//tipoProyecto
function listarTipoProyectoCombo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
  };
  return fetch(
    `${Url}tipoproyecto/listarTipoProyectoCombo`,
    requestOptions
  ).then(HandleResponse);
}

function obtenerTipoProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipoproyecto/obtenerTipoProyecto`, requestOptions).then(
    HandleResponse
  );
}

function validarTipoProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipoproyecto/validarTipoProyecto`, requestOptions).then(
    HandleResponse
  );
}

function crearTipoProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipoproyecto/crearTipoProyecto`, requestOptions).then(
    HandleResponse
  );
}
function actualizarTipoProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipoproyecto/actualizarTipoProyecto`,
    requestOptions
  ).then(HandleResponse);
}
function eliminarTipoProyecto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipoproyecto/eliminarTipoProyecto`, requestOptions).then(
    HandleResponse
  );
}

//Perfil Acceso
function listarPerfilAcceso(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
  };
  return fetch(`${Url}perfilacceso/listarPerfilAcceso`, requestOptions).then(
    HandleResponse
  );
}
function registrarPerfilAcceso(data) {
  debugger;
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfilacceso/registrarPerfilAcceso`, requestOptions).then(
    HandleResponse
  );
}
function actualizarPerfilAcceso(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}perfilacceso/actualizarPerfilAcceso`,
    requestOptions
  ).then(HandleResponse);
}
function eliminarPerfilController(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}perfilacceso/eliminarPerfilController`,
    requestOptions
  ).then(HandleResponse);
}
function validarPerfilController(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}perfilacceso/validarPerfilController`,
    requestOptions
  ).then(HandleResponse);
}
function llenarcomboPerfil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfilacceso/llenarcomboPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function llenarComboSubMenuPerfil(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}perfilacceso/llenarComboSubMenuPerfil`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerPerfilFiltrado(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}perfilacceso/obtenerPerfilFiltrado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Tipo Campo
function listarTipoCampo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipocampo/listarTipoCampo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearTipoCampo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipocampo/crearTipoCampo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarTipoCampo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipocampo/actualizarTipoCampo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarTipoCampo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipocampo/eliminarTipoCampo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarTipoCampo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipocampo/validarTipoCampo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Parametros
function listarTiposCampos() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${Url}tipocampo/listarTipoCampoCombo`, requestOptions).then(
    HandleResponse
  );
}

function listarParametros(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}parametros/listarParametros`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearParametros(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parametros/crearParametros`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function actualizarParametros(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parametros/actualizarParametros`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarParametros(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}parametros/eliminarParametros`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarParametros(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}parametros/validarParametros`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*--------- Políticas ---------*/
function listarPolitica(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}politica/listarPolitica`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectEtapa(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}politica/selectEtapa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function selectManzana(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}politica/selectManzana`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearFormulario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}politica/crearFormulario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/*------------------------------*/

function listarProductos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}producto/listarProductos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearProducto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}producto/crearProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarProducto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}producto/actualizarProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarProducto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}producto/eliminarProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de fuentes*/

function getFuentes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}fuentes/listarFuentes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      //console.log("lisatdo"+response)
      return response;
    });
}

function createFuentes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}fuentes/crearFuentes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarFuentes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}fuentes/actualizarFuentes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarFuentes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}fuentes/eliminarFuentes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de fuentes*/

function getTipoGastos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tiposgastos/listarTipoGastos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createTipoGastos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tiposgastos/crearTipoGastos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarTipoGastos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tiposgastos/actualizarTipoGastos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarTipoGastos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tiposgastos/eliminarTipoGastos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de planes*/

function getPlanes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}planes/listarPlanes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createPlanes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planes/crearPlanes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPlanes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planes/actualizarPlanes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarPlanes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planes/eliminarPlanes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de plazos*/

function getPlazos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}plazos/listarPlazos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createPlazos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}plazos/crearPlazos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPlazos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}plazos/actualizarPlazos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarPlazos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}plazos/eliminarPlazos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de prestadores*/

function getPrestadores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}prestadores/listarPrestadores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createPrestadores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}prestadores/crearPrestadores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPrestadores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}prestadores/actualizarPrestadores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarPrestadores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}prestadores/eliminarPrestadores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de planes por prestadores*/

function getPlanPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}planesxprestadores/listarPlanesxPrestadores`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function ContratoClientePlanes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}Ventas/ContratoClientePlanes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createPlanPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}planesxprestadores/crearPlanesxPrestadores`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function registroPlanPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}planesxprestadores/crearPlanesxPrestadores`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPlanPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}planesxprestadores/actualizarPlanesxPrestadores`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarPlanPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}planesxprestadores/eliminarPlanesxPrestadores`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function comboPlanes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planesxprestadores/comboPlanes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function comboPlazos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planesxprestadores/comboPlazos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function comboPrestadores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planesxprestadores/comboPrestadores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validacionPlanPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planesxprestadores/validarPlanPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function valorMeta(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}planesxprestadores/valorMeta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones de procesos*/

function listarProcesos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}procesos/listarProcesos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function llenarComboFuentes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}procesos/llenarComboFuentes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearProceso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}procesos/crearProceso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarProceso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}procesos/actualizarProceso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarProceso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}procesos/eliminarProceso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function ejecutarProceso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}procesos/ejecutarProceso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Obtener Discapacidad
function ObtenerDiscapacidad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}discapacidad/ObtenerDiscapacidad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Eliminar Discapacidad
function eliminarDiscapacidad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}discapacidad/eliminarDiscapacidad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Actualizar Discapacidad
function actualizarDiscapacidad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}discapacidad/actualizarDiscapacidad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Crear Discapacidad
function crearDiscapacidad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}discapacidad/crearDiscapacidad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Actualizar ProspectoCliente
function actualizarCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/actualizarCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar ClienteCargaFamiliar
function clientecargafamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerClienteCargaFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar EliminarCargaFamiliar
function eliminar_clientecargafamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carga_familiar/eliminarCargaFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar CuentaBancariaCliente
function clientecuentabancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}cuentas_bancarias_clientes/obtenerClienteCuentas`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar EliminarCuentaBancariaCliente
function eliminar_clientecuentabancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}cuentas_bancarias_clientes/eliminarCuentaCliente`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar RegistrarCuentaBancariaCliente
function registrar_clientecuentabancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}cuentas_bancarias_clientes/crearCuentaCliente`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar ActualizarCuentaBancariaCliente
function actualizar_clientecuentabancaria(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}cuentas_bancarias_clientes/actualizarCuentaCliente`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar ActualizarCargaFamiliarCliente
function actualizar_clientecargafamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carga_familiar/actualizarCargaFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar CrearCargaFamiliarCliente
function crear_clientecargafamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carga_familiar/crearCargaFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Eliminar Cliente
function eliminar_cliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/eliminarCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Obtener combos clientes
function combos_cliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerCombosClientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones para tipo de documentos*/

function listarTipoDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}tipodocumentos/listarTipoDocumentos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarTipoDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipodocumentos/validarTipoDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function CrearTipoDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipodocumentos/crearTipoDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarTipoDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipodocumentos/actualizarTipoDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EliminarTipoDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipodocumentos/eliminarTipoDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarcomboTipoPersona(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}tipodocumentos/comboTipoPersona`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//Consultar ClienteCargaFamiliar
function obtenerClientes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerClientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar clientevivienda
function obtenerviviendasclientes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}viviendacliente/obtenerviviendasclientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//eliminar clientevivienda
function eliminarViviendaCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}viviendacliente/eliminarViviendaCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crear clientevivienda
function crearViviendaCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}viviendacliente/crearViviendaCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Consultar clientevehiculo
function obtenervehiculosclientes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}vehiculocliente/obtenervehiculosclientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Crear clientevehiculo
function crearvehiculoCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}vehiculocliente/crearvehiculoCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//eliminar clientevehiculo
function eliminarVehiculoCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}vehiculocliente/eliminarVehiculoCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//obtener clientediscapacidad
function obtenerdiscapacidadclientes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}discapacidadcliente/obtenerdiscapacidadclientes`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crear clientediscapacidad
function creardiscapacidadCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}discapacidadcliente/creardiscapacidadCliente`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//eliminar clientediscapacidad
function eliminardiscapacidadCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}discapacidadcliente/eliminardiscapacidadCliente`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//obtener filtro de clientes interesados
function obtenerClienteFiltrado(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerClienteFiltrado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crear cabcargafamiliar
function crearCabCargafamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carga_familiar/crearCabCargafamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//Cliente Especifico para el actualizaar prospectos
function obtenerClienteEspecifico(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerClienteEspecifico`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//obtiene el detalle familiar del cliente
function obtenerFamiliaresCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}carga_familiar/obtenerFamiliaresCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//obtiene el ingreso del cliente
function obtenerClienteIngresos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ingresocliente/obtenerClienteIngresos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crea el ingreso del cliente
function crearClienteIngreso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ingresocliente/crearClienteIngreso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//elimina el ingreso del cliente
function eliminarClienteIngreso(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ingresocliente/eliminarClienteIngreso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//obtiene el gasto del cliente
function obtenerClienteGastos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}gastocliente/obtenerClienteGastos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crea el gasto del cliente
function crearClienteGasto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}gastocliente/crearClienteGasto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//elimina el gasto del cliente
function eliminarClienteGasto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}gastocliente/eliminarClienteGasto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//obtiene el activo del cliente
function obtenerClienteActivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}activocliente/obtenerClienteActivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crea el activo del cliente
function crearClienteActivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}activocliente/crearClienteActivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//elimina el activo del cliente
function eliminarClienteActivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}activocliente/eliminarClienteActivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//obtiene el pasivo del cliente
function obtenerClientePasivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}pasivocliente/obtenerClientePasivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//crea el pasivo del cliente
function crearClientePasivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}pasivocliente/crearClientePasivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
//elimina el pasivo del cliente
function eliminarClientePasivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}pasivocliente/eliminarClientePasivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

// Carga Combo nivel laboral del cliente
function ComboLaboralCargoClientes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cargolaboral/ComboLaboralCargoClientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

// funciones para tipo de familiares MR
function getTipoFamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipofamiliar/listarTipoFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function createTipoFamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipofamiliar/crearTipoFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarTipoFamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipofamiliar/eliminarTipoFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarTipoFamiliar(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tipofamiliar/actualizarTipoFamiliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerClienteInformacionPDF(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerClienteInformacionPDF`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

// funciones para tipo de negocios MR
function listarTipoNegocios(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tiponegocios/listarTiposNegocios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearTipoNegocio(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tiponegocios/crearTiposNegocios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function eliminarTipoNegocio(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tiponegocios/eliminarTiposNegocios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarTipoNegocios(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tiponegocios/actualizarTipoNegocios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarTipoNegocio(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}tiponegocios/validarNegocio`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Obtener la provincia en base al pais */
function obtenerProvincia(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerProvincia`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Obtener la ciudad en base a la provincia */
function obtenerCiudad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerCiudad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Obtener el pais en base a la provincia */
function obtenerPais(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerPais`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Obtener tipo documentos riesgo */
function comboTipoDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/comboTipoDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Obtener el pais y provincia en base a la ciudad */
function obtenerProvinciaPais(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/obtenerProvinciaPais`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Registras los documentos uno por uno */
function RegistroDocumentos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/RegistroDocumentos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Descargar PDF interesasdos */
function bajarArchivo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/bajarArchivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

/*funciones para clasificacion de documentos*/

function listarClasificacionDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}clasif_doc/listarClasificacionDocumentos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarClasificacionDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}clasif_doc/validarClasificacionDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function CrearClasificacionDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}clasif_doc/crearClasificacionDocumento`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarClasificacionDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}clasif_doc/actualizarClasificacionDocumento`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EliminarClasificacionDocumento(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}clasif_doc/eliminarClasificacionDocumento`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function listarComboTipoPersona(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}clasif_doc/comboTipoPersona`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarClasificaciones(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}clasif_doc/comboClasificaciones`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Asignar Asesor de riesgo */
function AsignarRiesgo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/AsignarRiesgo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function listarClasificacionesPorPersona(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}tipodocumentos/comboClasificacionesPorPersona`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerClientes_AnalistaRiesgo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}riesgo_cliente/obtenerClientes_AnalistaRiesgo`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function filtrarClientes_AnalistaRiesgo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}riesgo_cliente/filtrarClientes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Fncino para registrar los planes del interesado */
function PlanesInteresado(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}cliente/PlanesInteresado`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Listar Flujo X prestador */
function ListarFlujoXPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}FlujoXPrestador/ListarFlujoXPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Listar Flujo X prestador */
function EditarFlujoXPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}FlujoXPrestador/EditarFlujoXPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Listar Flujo X prestador */
function CrearFlujoXPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}FlujoXPrestador/CrearFlujoXPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Listar Flujo X prestador */
function EliminarFlujoXPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}FlujoXPrestador/EliminarFlujoXPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Filtrar Flujo X prestador */
function FiltrarFlujoXPrestador(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}FlujoXPrestador/FiltrarFlujoXPrestador`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/* Obtener Flujo X prestador */
function ListarActividadesXFlujo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}FlujoXPrestador/ListarActividadesXFlujo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerUsuarioSesion(id_usuario) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(
    `${Url}configuracioncuenta/obtenerUsuarioSesion/${id_usuario}`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function obtenerProveedorSesion(id_usuario) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(
    `${Url}configuracioncuenta/obtenerProveedorSesion/${id_usuario}`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listadoDatosFacturacionUsuario(id_usuarioSesion) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };
  return fetch(
    `${Url}facturacion/listado_datos_usuario/${id_usuarioSesion}`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearCuentaFormulario(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}crearcuentaformulario/crearCuentaFormulario`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerUsuariosIdentificacion(data) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(
    `${Url}crearcuentaformulario/obtenerUsuariosIdentificacion`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}configuracioncuenta/actualizarProveedor`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarInfPUsuario(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}configuracioncuenta/actualizarInfPUsuario`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarPasswordUsuario(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}configuracioncuenta/actualizarPasswordUsuario`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validar_carga_usuario(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}usuario/validar_carga_usuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function CargaMasivaUsuario_mantenimiento(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}usuario/creacionUsuarioMasivo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}




function ValidarCargaProducto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}producto/ValidarCargaProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function CargaMasivaEstadoProducto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}producto/CargaMasivaEstadoProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


