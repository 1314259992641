import { BehaviorSubject } from "rxjs";
import { Url } from "./Url.service";
import { NetworkService } from "./Network.service";

import { HandleResponse, AuthHeader } from "../_helpers";
// localhost:8000/api/auth/forgotPassword
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

//devuelve las funciones 
export const LandingService = {
  ingresarCorreo,
  validar_uuid,
  comboPersona,
  comboCiudades,
  registrar_usuario,
  insertarProveedor,
  insertarConstructor,
  insertarCliente,
  validarCoidigoRegistro,
  reenviarCorreo,
  verificarCodigoPrecio,
  formulariobanco,
  existeclientes,
  CorreoExiste,
  verificarUsuarioAuditor
};

function verificarCodigoPrecio(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auths/verificarCodigoPrecio`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}

function ingresarCorreo(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auth/ingresandoCorreo`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}

function validar_uuid(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auth/validarToken`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}

function comboPersona(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auth/comboPersona`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}


function comboCiudades(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auth/comboCiudades`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}


function registrar_usuario(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auth/registrarUsuario`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}

function insertarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auths/insertarProveedor`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}
function insertarConstructor(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auths/insertarConstructor`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}

function insertarCliente(data) {
  const requestOptions = {
    method: "POST",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auths/insertarCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => { return response; });
}
function validarCoidigoRegistro(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auths/validarCoidigoRegistro`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function reenviarCorreo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}auths/reenviarCorreo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function formulariobanco(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}formulario/formulariobanco`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function existeclientes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}formulario/ExisteClienteFormulario`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function CorreoExiste(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}formulario/CorreoExiste`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
function verificarUsuarioAuditor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}formulario/verificarUsuarioAuditor`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

