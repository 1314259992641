import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Spin } from "antd";
import "antd/dist/antd.css";
import "./assets/scss/style.scss";
import "./assets/scss/estilosPersonalizados.css";
import { AbilityContext } from '../src/redux/permissions/Can';
import ability from '../src/redux/permissions/defineAbility';
import 'gf-react-icons/dist/index.css';
import MaintenancePage from "./views/ConfiguracionGenerales/MaintenancePage"; // Importa la página de mantenimiento aquí
import { MantenimientosGeneralesService } from "./jwt/_services/MantenimientosGenerales.service";
import ErrorBoundary from "./components/ErrorBoundary";
import { AMBIENTE } from "../src/jwt/_services/Url.service";
const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./app")), 0);
    })
);

const MainApp = () => {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [statusManteninanceServices, SetSatusManteninanceServices] = useState(false);
  const [textMantenninanceServices, SetTextMantenninanceServices] = useState("");
  useEffect(() => {
    const obtenerEstadoMantenimiento = async () => {
      try {
        const response = await MantenimientosGeneralesService.EstadoMantenimientoKipit();

        if (response.estado_mantenimiento) {
          SetSatusManteninanceServices(true);
          SetTextMantenninanceServices(response.fecha_mantenimiento);
          localStorage.clear();
        } else {
          SetSatusManteninanceServices(false);
        }
      } catch (error) {
        SetSatusManteninanceServices(false);
      } finally {
        setLoading(false);
      }
    };

    obtenerEstadoMantenimiento();
  }, []);

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      />
    );
  }

  // Renderizamos la página de mantenimiento si el estado de mantenimiento está activado
  if (statusManteninanceServices) {
    return <MaintenancePage fecha={textMantenninanceServices} />;
  }
  console.log(`AMBIENTE : ${AMBIENTE}`)
  // Renderizamos la aplicación principal si el estado de mantenimiento no está activado
  return (
    (AMBIENTE === "PRODUCCION") ? (
      <ErrorBoundary>
        <Suspense fallback={<div />}>
          <AbilityContext.Provider value={ability}>
            <App />
          </AbilityContext.Provider>
          <div id="divCargando"></div>
        </Suspense>
      </ErrorBoundary>
    ) : (
      <Suspense fallback={<div />}>
        <AbilityContext.Provider value={ability}>
          <App />
        </AbilityContext.Provider>
        <div id="divCargando"></div>
      </Suspense>
    )
  );
};

ReactDOM.render(
  <MainApp />,
  document.getElementById("root")
);
