import React from "react";
import ErrorInterno from "../Errores/ErrorInterno";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para renderizar la UI alternativa
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // Puedes registrar el error en un servicio de monitoreo
    console.error("Error capturado por Error Boundary : ", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorInterno
          contenido={"Se esta solucionando la novedad."}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
