import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";


//devuelve las funciones
export const PromocionesService = {
  //comunicaciones
  listarPromociones,
  combosInformacion,
  
};

//Relacion Laboral
function listarPromociones(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}promociones/listarPromociones`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function combosInformacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}promociones/combosInformacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
