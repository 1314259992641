import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";

export const EmpresaTarjetaService = {
    listarEmpresaTarjeta,
    CrearEmpresaTarjeta,
    EditarEmpresaTarjeta,
    EliminarEmpresaTarjeta,
    ExisteCodigo
};


function listarEmpresaTarjeta() {
    const requestOptions = {
      method: "GET",
      headers: AuthHeader(),
    };
    return fetch(`${Url}empresaTarjeta/listarEmpresaTarjeta`, requestOptions)
      .then(HandleResponse)
      .then((response) => {
        return response;
      });
  }

function EliminarEmpresaTarjeta(data) {
    const requestOptions = {
      method: "DELETE",
      headers: AuthHeader(),
      body: JSON.stringify({ data }),
    };
    return fetch(`${Url}empresaTarjeta/EliminarEmpresaTarjeta`, requestOptions)
      .then(HandleResponse)
      .then((response) => {
        return response;
      });
}

function CrearEmpresaTarjeta(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}empresaTarjeta/CrearEmpresaTarjeta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EditarEmpresaTarjeta(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}empresaTarjeta/EditarEmpresaTarjeta`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function ExisteCodigo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}empresaTarjeta/ExisteCodigo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
