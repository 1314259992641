import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";


export const MantenimientosGeneralesService = {
    //comunicaciones
    EstadoMantenimientoKipit,
    obtenerValorIVA
}

function EstadoMantenimientoKipit() {
    const requestOptions = {
      method: "GET",
      headers: AuthHeader(),
    //   body: JSON.stringify(data),
    };
  
    return fetch(`${Url}configuraciones_generales/mantenimiento`, requestOptions)
      .then(HandleResponse)
      .then((response) => {
        return response;
      });
  }


  function obtenerValorIVA() {
    const requestOptions = {
      method: "GET",
      headers: AuthHeader(),
    //   body: JSON.stringify(data),
    };
  
    return fetch(`${Url}configuraciones_generales/obtener-iva`, requestOptions)
      .then(HandleResponse)
      .then((response) => {
        return response;
      });
  }