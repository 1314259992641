import { lazy } from "react";
const Dashboard = lazy(() => import("../../views/dashboard/Dashboard"));

export function ConfigureFakeBackend() {
  let users = [
    {
      id: 1,
      username: "test@test.com",
      password: "test",
      firstName: "Test",
      lastName: "User",
    },
  ];
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    const isLoggedIn =
      opts.headers["Authorization"] === "Bearer fake-jwt-token";

    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(() => {
        // authenticate - public
        if (url.endsWith("/users/authenticate") && opts.method === "POST") {
          const params = JSON.parse(opts.body);
          const user = users.find(
            (x) =>
              x.username === params.username && x.password === params.password
          );
          if (!user) return error("Username or password is incorrect");
          return ok(
          {
            user:{
            id: user.id,
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            token: "fake-jwt-token",
            time_token: 3600,
          },
          menu_data: [
            {
              collapse: true,
              path: "/dashboards",
              name: "Elementos",
              state: "dashboardpages",
              icon: "fa fa-home",
              badges: "side-badge badge badge-info",
              badgeno: "2",
              child: [
                {
                  path: "/dashboards/dashboard",
                  name: "Formulario",
                  mini: "B",
                  icon: "mdi mdi-adjust",
                  component: {},
                },
                {
                  path: "/dashboards/prueba",
                  name: "Mantenimiento",
                  mini: "B",
                  icon: "mdi mdi-adjust",
                  component: {},
                },
              ],
            },
            {
              path: "/",
              pathTo: "/dashboard",
              name: "Dashboard",
              redirect: true,
            },
          ],
          expired_in:3600,
          permission_data: [ 
            { action: 'read', subject: 'allow' }
          ]
        });
        }

        if (url.endsWith("/users/refresh_token") && opts.method === "POST") {
          const params = JSON.parse(opts.body);
          // const user = users.find(
          //   (x) =>
          //     x.username === params.username && x.password === params.password
          // );
          // if (!user) return error("Username or password is incorrect");
          return ok({token: "new-token", expired_in: 3600});
        }

        if (url.endsWith("/auth/forgotPassword") && opts.method === "POST") {
          const params = JSON.parse(opts.body);
          const user = users.find(
            (x) =>
              x.username === params.username
          );
          if (!user) return error("Username is incorrect");
          return ok({resultado:"enviado"});
        }

        if (url.endsWith("/users/menu") && opts.method === "POST") {
          const params = JSON.parse(opts.body);
          // const user = users.find(
          //   (x) =>
          //     x.username === params.username && x.password === params.password
          // );
          // if (!user) return error("Username or password is incorrect");
          return ok({
            path: "/dashboard",
            name: "Dashboard",
            icon: "ti-dashboard",
          },{ path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true });
        }

        // get users - secure
        if (url.endsWith("/users") && opts.method === "GET") {
          if (!isLoggedIn) return unauthorised();
          return ok(users);
        }

        // pass through any requests not handled above
        realFetch(url, opts).then((response) => resolve(response));

        // private helper functions

        function ok(body) {
          resolve({
            ok: true,
            text: () => Promise.resolve(JSON.stringify(body)),
          });
        }

        function unauthorised() {
          resolve({
            status: 401,
            text: () =>
              Promise.resolve(JSON.stringify({ message: "Unauthorised" })),
          });
        }

        function error(message) {
          resolve({
            status: 400,
            text: () => Promise.resolve(JSON.stringify({ message })),
          });
        }
      }, 500);
    });
  };
}
