import { async } from "rxjs";
import { LOGIN_RENEW_TOKEN, LOGIN_SUCCESS, LOGIN_ERROR } from "../constants/";
import { dispatch } from "rxjs/internal/observable/pairs";

const INIT_STATE = {
  authReducerResponse: "default",
  data_user: {},
  data_menu: [],
  data_permissions: {},
  isAuthenticated: false,
  time_token: 0,
  end_token: null,
  isFetching: false,
  nivel: null,
  nivel_promo: null,
  clienteproducto: false,
  visa_machala:false
};

const SET_CLIENTES_ASIGNADOS_PRODUCTOS = "SET_CLIENTES_ASIGNADOS_PRODUCTOS";
const SET_VISA_KIPIT = "SET_VISA_KIPIT"; ///

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_RENEW_TOKEN:
      return {
        ...state,
        end_token: new Date().setSeconds(
          new Date().getSeconds() + action.payload
        ),
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data_user: action.payload.user,
        data_menu: action.payload.menu_data,
        data_permissions: action.payload.permission_data,
        time_token: action.payload.user.expires_in,
        end_token: new Date().setSeconds(
          new Date().getSeconds() + action.payload.user.expires_in
        ), //sumando el tiempo actual con el tiempo del token ...
        isAuthenticated: true,
        isFetching: false,
        nivel: action.payload.nivel,
        nivel_promo: action.payload.nivel_promo,
        visa_machala: action.payload.visa_machala,///
      };
    case LOGIN_ERROR:
      return {
        authReducerResponse: "default",
        data_user: {},
        data_menu: [],
        data_permissions: {},
        time_token: 0,
        end_token: null,
        isAuthenticated: false,
        isFetching: false,
      };

    case SET_CLIENTES_ASIGNADOS_PRODUCTOS:
      return { ...state, clienteproducto: action.payload };
      case SET_VISA_KIPIT:
        return { ...state, visa_machala: action.payload };///
    default:
      return state;
  }
};

export const setClienteProductoAsignado = (obj) => async (dispatch, getState) => {
  try {
    //console.log("dispatch");
    //console.log(obj);
    dispatch({ type: SET_CLIENTES_ASIGNADOS_PRODUCTOS, payload: obj });
  } catch (err) {
    console.log(err);
  }
}

// export const setvisamachala = (obj) => async (dispatch, getState) => {
//   try {
//     //console.log("dispatch");
//     //console.log(obj);
//     dispatch({ type: SET_VISA_KIPIT, payload: obj });
//   } catch (err) {
//     console.log(err);
//   }
// }
