import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const ChatService = {
  chatpersonalizado,
  mensajeCrear
};


// solicitud/{id}/all
function chatpersonalizado(id) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
  };

  return fetch(`${Url}v1/chat/personalizado/${id}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};


// solicitud = POST
function mensajeCrear(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}v1/chat/mensaje`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};