import { useHistory } from "react-router-dom";
import separador from "../../assets/images/No_photo_available.png";
import React from "react";
//devuelve las funciones globales
export const UtilService = {
  validarCampo,
  mostrarCargando,
  ocultarCargando,
  soloNumeros,
  soloLetrasYNumeros,
  soloLetras,
  url,
  validarCedulaEcuatoriana,
  email,
  campoIngresado,
  PrimeraLetraCadaPalabraMayuscula,
  precise_round,
  stringToDate,
  ValidarN_Celular,
  soloDinero,
  validarCelularTelefono,
  validarCedulaRuc,
  formatPrice,
  imageError,
  cedulaExisteUsuario,
  correoExisteUsuario,
  letrasNumeros,
  sinEspacio,
  LimitarNumero5Decimales,
  isNumeric,
  EliminarTildes,
  ValidoNumeroCelular,
  removeReactComponents,
  Letras,
  formatDiscount,
  formatDiscountPrice,
  getCardTypeBackground
};
function getCardTypeBackground(type) {
  let cardInfo = {};
  switch (type) {
    case 'P2':
      cardInfo = {
        backgroundColor: 'gold',
        text: 'GOLD',
        color: 'gold',
      };
      break;

    case 'P3':
      cardInfo = {
        backgroundColor: 'silver',
        text: 'PLATINUM',
        color: 'silver',
      };
      break;
    case 'P4':
      cardInfo = {
        backgroundColor: 'black',
        text: 'BLACK',
        color: 'black',
      };
      break;

    default:
      cardInfo = {
        backgroundColor: 'white',
        text: 'Unknown',
        color: 'black',
      };
  }
  return cardInfo;
}


function formatPrice(price) {
  let price1 = parseFloat(price);
  if (Number.isNaN(price1)) {
    price1 = 0.0;
  }
  // return `$ ${price1.toFixed(2)}`;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price1);
}

function formatDiscountPrice(discount) {
  let discount1 = parseFloat(discount);
  if (Number.isNaN(discount1)) {
    discount1 = 0.0;
  }

  // Aplica el descuento negativo
  const discountedPrice = -discount1;

  // Agrega el símbolo de porcentaje al final del descuento formateado
  const formattedDiscount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(discountedPrice);

  return formattedDiscount;
}

function formatDiscount(discount) {
  let discount1 = parseFloat(discount);
  if (Number.isNaN(discount1)) {
    discount1 = 0.0;
  }

  // Aplica el descuento negativo
  const discountedPrice = -discount1;

  // Agrega el símbolo de porcentaje al final del descuento formateado
  const formattedDiscount = new Intl.NumberFormat("en-US", {
    style: "percent",
    signDisplay: "always",
    minimumFractionDigits: 0,
  }).format(discountedPrice / 100);

  return formattedDiscount;
}

function validarCampo(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/gi.test(value)) {
    error = "Solo se permite ingresar letras";
  }
  return error;
}
/* function soloNumeros(value) {
  let error;
 if (!/^[0-9]*$/gi.test(value)) {
    error = "Solo se permite ingresar numeros";
  }
  return error;
} */

// function soloNumeros(evt) {
//   const keyCode = evt.keyCode || evt.which;
//   const keyValue = String.fromCharCode(keyCode);
//   if (!/^[0-9.]+$/.test(keyValue))
//     evt.preventDefault();

//   console.log('sssssssssssssssssssssssdasda')

//     }

function mostrarCargando() {
  document.getElementById("divCargando").style.display = "block";
}
function ocultarCargando() {
  document.getElementById("divCargando").style.display = "none";
}

function soloLetrasYNumeros(value) {
  let error;
  if (!/^[A-Za-zÁÉÍÓÚáéíóúñÑ 0-9]*$/gi.test(value)) {
    error = "Solo se permite ingresar letras y numeros";
  }
  return error;
}
function letrasNumeros(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[A-Za 0-9]*$/gi.test(keyValue)) {
    event.preventDefault();
  }
}
function sinEspacio(value) {
  // value.preventDefault();
  if (value.trim() == "") {
    return "No puedes ingresar espacios";
  }
}
/* function soloLetras(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/gi.test(keyValue))
    event.preventDefault();
} */

function url(value) {
  let error;
  let validacion =
    /(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  if (!validacion.test(value)) {
    error = `url invalida \n
    ejemplo: http://www.google.com`;
  }
  return error;
}
function email(value) {
  let error;
  let validar = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  if (!validar.test(value)) {
    error = "Email Invalido";
  }
  return error;
}
function soloDinero(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[0-9,'.']*$/gi.test(keyValue)) event.preventDefault();
}
function validarCelularTelefono(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[0-9,'+']*$/gi.test(keyValue)) event.preventDefault();
}

function soloNumeros(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  if (!/^[0-9 .]*$/gi.test(keyValue)) {
    event.preventDefault();
  }
}

function soloLetras(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/gi.test(keyValue)) event.preventDefault();
}
function Letras(event) {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^[a-zA-Z\/]+$/gi.test(keyValue)) event.preventDefault();
}
function campoIngresado(value) {
  let error;
  if (!value) {
    error = "Ingrese Campo";
  }
  return error;
}
function PrimeraLetraCadaPalabraMayuscula(str) {
  //Reemplazamos los espacios seguidos por uno solo
  var texto = str?.replace(/[ ]+/g, " ");
  //Quitarmos los espacios del principio y del final
  texto = texto?.replace(/^ /, "");
  texto = texto?.replace(/ $/, "");
  //Troceamos el texto por los espacios
  var textoTroceado = texto?.split(" ");
  //Creamos la variable para almacenar el resultado
  var respuesta = "";
  textoTroceado.map((x) => {
    respuesta =
      respuesta + x.charAt(0).toUpperCase() + x.slice(1).toLowerCase() + " ";
  });
  return respuesta;
}

/*redonde un numero a la cantidad de decimales necesarios*/
/*  function precise_round(num,decimals) {
  var sign = num >= 0 ? 1 : -1;
  var numeroFinal="";
  var numero="";
  
  if (num==="")
    {  numeroFinal="$ 0.00"
    }
  else {
    numero=(Math.round((num*Math.pow(10,decimals)) + (sign*0.001)) / Math.pow(10,decimals)).toFixed(decimals);
    //numeroFinal="$ "+numero.toLocaleString('en-US', {maximumFractionDigits:2});
  }
  return "$ "+numero.toLocaleString();
} */

function precise_round(floatValue = 0, decimals = 0, multiplier = 1) {
  let floatMultiplied = floatValue * multiplier;
  let stringFloat = floatMultiplied + "";
  let arraySplitFloat = stringFloat.split(".");
  let decimalsValue = "0";
  if (arraySplitFloat.length > 1) {
    decimalsValue = arraySplitFloat[1].slice(0, decimals);
  }
  let integerValue = arraySplitFloat[0];
  let arrayFullStringValue = [integerValue, decimalsValue];
  let FullStringValue = arrayFullStringValue.join(".");
  let floatFullValue = parseFloat(FullStringValue) + "";
  let formatFloatFullValue = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: decimals,
  }).format(floatFullValue);
  return formatFloatFullValue;
}

/*formataea un fecha a dd-mm-yyyy*/
function stringToDate(_date, _format, _delimiter) {
  if (_date !== null) {
    var date = new Date();
    let mes;
    let dia;
    var nueva = _date.split(" ")[0].split("-").reverse().join("-");
    return nueva;
  }
}
/* Valido el numero de celular */
function ValidarN_Celular(value) {
  if (value.length > 0 && value.length < 10) {
    return "El numero de celular debe contener 10 digitos";
  }
}

function imageError(currentTarget) {
  // console.log(currentTarget.currentTarget);
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = separador;
}

function cedulaExisteUsuario(usuarios, input) {
  let error = "";
  for (let i in usuarios) {
    for (let j in usuarios[i]) {
      if (usuarios[i][j] == input) {
        let correoSeparado = usuarios[i]["correo"].split("@");
        let correoOculto =
          correoSeparado[0].substring(0, 3) + "*" + "@" + correoSeparado[1];
        error = `cedula ya existe con correo ${correoOculto}`;
        return error;
      }
    }
  }
}
function correoExisteUsuario(usuarios, input) {
  let error = "";

  for (let i in usuarios) {
    for (let j in usuarios[i]) {
      if (usuarios[i][j] == input) {
        //console.log(usuarios[i][j])
        error = `Correo ya registrado`;
        return error;
      }
    }
  }
}

/* Validar la cedula o si es RUC */
function validarCedulaRuc(value) {
  if (value?.length > 0 && value !== null) {
    var numero = value;
    var suma = 0;
    var residuo = 0;
    var pri = false;
    var pub = false;
    var nat = false;
    var numeroProvincias = 22;
    var modulo = 11;
    let error = "";
    /* Verifico que el campo no contenga letras */
    var ok = 1;
    for (var i = 0; i < numero.length && ok == 1; i++) {
      var n = parseInt(numero.charAt(i));
      if (isNaN(n)) ok = 0;
    }
    if (ok == 0) {
      error = "No puede ingresar caracteres en el número";
      return error;
    }
    if (numero.length < 10) {
      error = "El número ingresado no es válido";
      return error;
    }
    /* Los primeros dos digitos corresponden al codigo de la provincia */
    var provincia = numero.substr(0, 2);
    if (provincia < 1 || provincia > numeroProvincias) {
      error = "El código de la provincia (dos primeros dígitos) es inválido";
      return error;
    }
    /* Aqui almacenamos los digitos de la cedula en variables. */
    var d1 = numero.substr(0, 1);
    var d2 = numero.substr(1, 1);
    var d3 = numero.substr(2, 1);
    var d4 = numero.substr(3, 1);
    var d5 = numero.substr(4, 1);
    var d6 = numero.substr(5, 1);
    var d7 = numero.substr(6, 1);
    var d8 = numero.substr(7, 1);
    var d9 = numero.substr(8, 1);
    var d10 = numero.substr(9, 1);
    /* El tercer digito es: */
    /* 9 para sociedades privadas y extranjeros   */
    /* 6 para sociedades publicas */
    /* menor que 6 (0,1,2,3,4,5) para personas naturales */
    if (d3 == 7 || d3 == 8) {
      error = "El tercer dígito ingresado es inválido";
      return error;
    }
    /* Solo para personas naturales (modulo 10) */
    if (d3 < 6) {
      nat = true;
      var p1 = d1 * 2;
      if (p1 >= 10) p1 -= 9;
      var p2 = d2 * 1;
      if (p2 >= 10) p2 -= 9;
      var p3 = d3 * 2;
      if (p3 >= 10) p3 -= 9;
      var p4 = d4 * 1;
      if (p4 >= 10) p4 -= 9;
      var p5 = d5 * 2;
      if (p5 >= 10) p5 -= 9;
      var p6 = d6 * 1;
      if (p6 >= 10) p6 -= 9;
      var p7 = d7 * 2;
      if (p7 >= 10) p7 -= 9;
      var p8 = d8 * 1;
      if (p8 >= 10) p8 -= 9;
      var p9 = d9 * 2;
      if (p9 >= 10) p9 -= 9;
      modulo = 10;
    } else if (d3 === 6) {
      /* Solo para sociedades publicas (modulo 11) */
      /* Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 */
      pub = true;
      p1 = d1 * 3;
      p2 = d2 * 2;
      p3 = d3 * 7;
      p4 = d4 * 6;
      p5 = d5 * 5;
      p6 = d6 * 4;
      p7 = d7 * 3;
      p8 = d8 * 2;
      p9 = 0;
    } else if (d3 === 9) {
      /* Solo para entidades privadas (modulo 11) */
      pri = true;
      p1 = d1 * 4;
      p2 = d2 * 3;
      p3 = d3 * 2;
      p4 = d4 * 7;
      p5 = d5 * 6;
      p6 = d6 * 5;
      p7 = d7 * 4;
      p8 = d8 * 3;
      p9 = d9 * 2;
    }
    suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
    residuo = suma % modulo;
    /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
    var digitoVerificador = residuo == 0 ? 0 : modulo - residuo;
    /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/
    if (pub == true) {
      if (digitoVerificador != d9) {
        error = "El ruc de la empresa del sector público es incorrecto.";
        return error;
      }
      /* El ruc de las empresas del sector publico terminan con 0001*/
      if (numero.substr(9, 4) != "0001") {
        error =
          "El ruc de la empresa del sector público debe terminar con 0001";
        return error;
      }
    } else if (pri == true) {
      if (digitoVerificador != d10) {
        error = "El ruc de la empresa del sector privado es incorrecto.";
        return error;
      }
      if (numero.substr(10, 3) != "001") {
        error = "El ruc de la empresa del sector privado debe terminar con 001";
        return error;
      }
    } else if (nat == true) {
      if (digitoVerificador != d10) {
        error = "El número de cédula de la persona natural es incorrecto.";
        return error;
      }
      if (numero.length > 10 && numero.substr(10, 3) != "001") {
        error = "El ruc de la persona natural debe terminar con 001";
        return error;
      }
    }
    return "";
  }
}

function LimitarNumero5Decimales(numero) {
  return numero?.toString()?.match(/^-?\d+(?:\.\d{0,5})?/)[0];
}

function isNumeric(numero) {
  return /^-?\d+$/.test(numero);
}

function EliminarTildes(texto) {
  return texto?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
}

function ValidoNumeroCelular(numero) {
  if (numero != null && numero != undefined && numero != "") {
    var numero_nacional = false;
    var numero_internacional = false;
    // Valido sea un numero
    var expresionValidarNumero = /^[0-9+]+$/;
    if (!expresionValidarNumero.test(numero)) {
      return "El numero de celular contiene mas que solo digitos";
    }
    // Valido que tenga 10 digitos
    if (numero.length == 10) {
      numero_nacional = true;
    } else if (numero.length >= 12 && numero.length <= 13) {
      numero_internacional = true;
    } else {
      return "El número de celular no contiene la longitud correcta";
    }
    // Valido que sea un numero nacional
    if (numero_nacional) {
      var expresionValidarNumeroNacional = /^09[0-9]+$/;
      if (!expresionValidarNumeroNacional.test(numero)) {
        return "El número de celular nacional no es valido";
      }
    }
    // Valido que sea un numero internacional
    if (numero_internacional) {
      var expresionValidarNumeroInternacional = /^\+[0-9]+$/;
      if (!expresionValidarNumeroInternacional.test(numero)) {
        return "El número de celular internacional no es valido";
      }
    }
  }
  return "";
}



function removeReactComponents(obj) {
  const jsonString = JSON.stringify(obj, (key, value) => {
    if (typeof value === 'function') {
      return value.toString();
    } else if (React.isValidElement(value)) {
      return '[React Component]';
    } else {
      return value;
    }
  });

  return JSON.parse(jsonString);
}

function validarCedulaEcuatoriana(cedula) {
  // Verificar que la cédula tenga 10 dígitos
  if (cedula.length !== 10) {
    return false;
  }

  // Extraer el dígito verificador de la cédula
  const digitoVerificador = parseInt(cedula.charAt(9));

  // Extraer los primeros 9 dígitos de la cédula
  const cedulaBase = cedula.substring(0, 9);

  // Calcular el dígito verificador esperado
  let suma = 0;

  for (let i = 0; i < 9; i++) {
    let digito = parseInt(cedulaBase.charAt(i));

    if (i % 2 === 0) {
      digito *= 2;

      if (digito > 9) {
        digito -= 9;
      }
    }

    suma += digito;
  }

  const digitoEsperado = 10 - (suma % 10);

  // Comparar el dígito verificador de la cédula con el dígito verificador esperado
  return digitoVerificador === digitoEsperado;
}

