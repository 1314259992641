import { BehaviorSubject } from "rxjs";
import { Url } from "./Url.service";
import { NetworkService } from "./Network.service";
import { UrlLogout } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
// localhost:8000/api/auth/forgotPassword
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const maintenanceSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("maintenance"))
);

//devuelve las funciones 
export const AuthenticationService = {
  login,
  refresh_token,
  recuperar_clave,
  cambiar_clave,
  logout,
  validar_uuid,
  getDatabase,
  getPerfilDataBase,
  getLoginPantalla,
  getInicio,
  ExisteClienteProducto,
  currentUser: currentUserSubject.asObservable(),

  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login2(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${Url}auth/login`, requestOptions)
    .then(HandleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user.text));
      currentUserSubject.next(user.text);
      return user.text;
    });
}

function login(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${Url}auth/login`, requestOptions)
    .then(HandleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user.text));
      currentUserSubject.next(user.text);
      return user.text;
    });
}

function ExisteClienteProducto(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}auth/ExisteClienteProducto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

};

function mantenimiento() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${Url}auth/mantenimiento`, requestOptions)
    .then(HandleResponse)
    .then((data) => {
      maintenanceSubject.next(data.text);
      return data.text;
    });
}

function refresh_token(token) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ token }),
  };

  return fetch(`${Url}admin/refresh`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      var user = JSON.parse(localStorage.getItem("currentUser"));
      var _token = { expires_in: response.text.expires_in };
      user.user.token = response.text.access_token;
      localStorage.removeItem("currentUser");
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return response.text.expires_in;
    });
}


function logout() {
  localStorage.clear('persist:persistedStore');
  localStorage.clear('persist:root');
  localStorage.clear();
  window.location.href = UrlLogout;
  //window.location.reload(true);
  //currentUserSubject.next(null);
  // crear variable de entorno redirect_logout
  setTimeout(() => {
    //window.location.href="/authentication/login";
    // console.log('12')
    // debugger
    // currentUserSubject.next(null);
    //  window.location.reload(true);
  }, 1000);
  // window.location.href="https://kipit.shop/images/landing.html";
}

function getLoginPantalla() {
  // remove user from local storage to log user out

  // window.location.href="http://localhost:3000/authentication/login";
  window.location.href = "http://186.3.211.79:5004/authentication/login";
  //window.location.hash = "#/authentication/login";


}

function getInicio() {
  // remove user from local storage to log user out

  // window.location.href="http://localhost:3000/authentication/login";
  //window.location.href="http://186.3.211.79:5004/authentication/login";
  window.location.href = "https://kipit.shop/images/landing.html";
  //window.location.hash = "#/authentication/login";


}

function recuperar_clave(correo) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ correo }),
  };

  return fetch(`${Url}auth/forgotPassword`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return response;
    });
}

function validar_uuid(token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  };

  return fetch(`${Url}auth/confirmToken`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return response;
    });
}


function cambiar_clave(token, clave) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token, clave }),
  };

  return fetch(`${Url}auth/resetPassword`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function getDatabase(correo, clave) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ correo, clave }),
  };

  return fetch(`${Url}auth/getDatabase`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response.text;
    });
}

function getPerfilDataBase(correo, dataBase) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ correo, dataBase }),
  };

  return fetch(`${Url}auth/getPerfilDataBase`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response.text;
    });
}



