import React from "react";
import { Row, Col } from 'antd';
import LogoKipit from '../../assets/images/KIPIT-NARANJA.png';

const MaintenancePage = (props) => {
  return (
    <div style={styles.container}>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        {/* En dispositivos móviles, muestra una columna debajo de la otra */}
        <Col xs={24} md={12} style={styles.column}>
          <img src={LogoKipit} alt="Maintenance" style={styles.image} />
        </Col>
        <Col xs={24} md={12} style={styles.column}>
          <p style={styles.text}>
            <div style={styles.p1}>El sitio está en mantenimiento.</div>
            Por favor, vuelve más tarde.        
            {props.fecha ? ( 
              <div style={{marginTop: "15px", fontWeight: 400}}> 
                Finalizacíon del mantenimiento: <div style={{fontWeight: 700}}>{props.fecha}</div>
              </div>
            ) : null}
          </p>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
   // Espacio entre las columnas
  },
  image: {
    width: '75%', // Ajusta el tamaño de la imagen según tus necesidades
  },
  text: {
    fontSize: '18px', // Tamaño del texto
    color: "#d5570c"
  },
  p1: {
    fontSize: "35px",
    fontWeight: "600",
    marginBottom: "12px"
  }
};

export default MaintenancePage;
