import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const OrdenesService = {
  //ordenes
  listarOrdenes,
  filtrarOrdenes,
  listarDetalleOrdenes,
  actualizarOrden,
  ejecutarProceso,
  obtenerOrdenInformacionPDF,
  listarHistoricoEstados,
  listarEstados,
  listarTiposDocumentos,
  listarDocumentosPorOrden,
  RegistroDocumentos,
  bajarArchivo,
  ejecutarProcesoOrdenes,
  listarMovimientosOrden,
  obtenerOrden,
  procesarOrden,
  ordenCompraCupo,
  eliminarOrden,
  getOrdenesNotify,
  getOrdenesNotifyPT,
  //proveedor
  obtenerProveedores,
  validarProveedor,
  crearProveedor,
  actualizarProveedor,
  eliminarProveedor,
  llenarComboProveedor,

  //conciliar ordenes
  listarOrdenesConciliar,
  conciliarOrden,

  //actuaizar orden
  actualizarDetalleOrden,

  //datos facturacion
  obtenerDatosCliente,
  obtenerMetodosPago,

  obtenerOrdenesGeneradas,
  listarEstadosHijos,

  //dashboard
  listarNotificacionesOrden,

  comboProyectos,
  crearProyecto,

  listarProveedores,
  cargarTerminos,
  generarExcelOrdenes,

  reprocesarOrden,
  //agg m
  listarEstadosMOrdenes,
  listado_ordenes_home,
  listado_detalle_ordenes_home,
  aceptar_orden,
  rechazar_orden,
  despachar_orden,
  listar_combo_bodegas,
  listar_combos_motivos,
  editar_detalle_ordenes_home,
  editar_detalle_ordenes_home_unidad,
  getOrdenesFacturadas,
  getOrdenesNoFacturadas,
  facturarOrden,

  // Ordenes_hormigon
  listado_ordenes_hormigon,
  listado_orden_hormigon,
  asignada_hormigon,
  programacion_hormigon,
  entregada_hormigon,
  anuladas_hormigon,
  listado_hormigon,
  cambiar_cantidad_hormigon,
  listar_motivos_hormigon,
  CargaMasivaDeHormigon,

  //// ORDENES PROVEEDOR SHOP
  listado_ordenes_Admin,
  aceptar_shop,
  programacion_shop,
  entregada_shop,
  anuladas_shop,

  aceptar_Admin,
  programacion_Admin,
  entregada_Admin,
  aceptar_orden_Admin,
  rechazar_Admin,
  despachar_Admin,

  // logistica 
  listar_combos_motivos_Log,
  orden_home_logistica,
  listado_ordenes_home_admin,
  ordenlogistica,
  retirar_orden,
  entregada_orden,
  devueltas_orden,
  cambiar_cantidad,
  rechazar_producto_logistica,

  //Carga masiva de Hormigon
  
  validar_carga_hormigon

};

function validar_carga_hormigon(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/validar_carga_hormigon`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}


function rechazar_producto_logistica(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/rechazar_producto_logistica`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}
function facturarOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/facturarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}
function getOrdenesFacturadas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/getOrdenesFacturadas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function getOrdenesNoFacturadas(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/getOrdenesNoFacturadas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function editar_detalle_ordenes_home(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/editar_detalle_ordenes_home`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function editar_detalle_ordenes_home_unidad(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/editar_detalle_ordenes_home_unidad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function eliminarOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/eliminarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function generarExcelOrdenes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/generarExcelOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}


function cargarTerminos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/cargarTerminos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function listarProveedores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarProveedores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function crearProyecto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/crearProyecto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });/*.catch(() => {
      return NetworkService.errorRed();
    }); */
}

function comboProyectos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/comboProyectos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function listarNotificacionesOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarNotificacionesOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}



function obtenerOrdenesGeneradas(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerOrdenesGeneradas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function procesarOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/procesarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });

  /*  return fetch(`${Url}ordenes/procesarOrden`, requestOptions)
     .then(HandleResponse)
     .then((response) => {
       return response;
     })
    .catch(() => {
      return response;
     }); */
}

function ordenCompraCupo(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/ordenCompraCupo`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function obtenerMetodosPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerMetodosPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerDatosCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerDatosCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

function listarMovimientosOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarMovimientosOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    }).catch(() => {
      return NetworkService.errorRed();
    });
}

//proveedor
function llenarComboProveedor(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}proveedor/llenarComboProveedor`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerProveedores(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}proveedor/obtenerProveedor`,
    requestOptions
  ).then(HandleResponse)
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function validarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/validarProveedor`,
    requestOptions
  ).then(HandleResponse)
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function crearProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/crearProveedor`,
    requestOptions
  ).then(HandleResponse)
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function actualizarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/actualizarProveedor`,
    requestOptions
  ).then(HandleResponse)
    .catch(() => {
      return NetworkService.errorRed();
    });
}
function eliminarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/eliminarProveedor`,
    requestOptions
  ).then(HandleResponse);

}

//Ordenes
function listarOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listarOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function filtrarOrdenes(data) {
  debugger;
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/filtrarOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}


function listarDetalleOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarDetalleOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}analisis/actualizarDefiniciones`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function ejecutarProceso(data) {

  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/ejecutarProceso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/actualizarOrden`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerOrdenInformacionPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/crearPDForden`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function listarHistoricoEstados(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarHistoricoEstados`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarEstados(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarEstados`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function listarEstadosMOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarEstadosMOrdenes`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}





function listarEstadosHijos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarEstadosHijos`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function listarTiposDocumentos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarTiposDocumentos`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function listarDocumentosPorOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarDocumentosPorOrden`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function RegistroDocumentos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/RegistroDocumentos`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function bajarArchivo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/bajarArchivo`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function ejecutarProcesoOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/ejecutarProceso`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//conciliar ordenes
function listarOrdenesConciliar(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}conciliar/listarOrdenesConciliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function conciliarOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}conciliar/conciliarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}


function actualizarDetalleOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}ordenes/actualizarDetalleOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function reprocesarOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/reprocesarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listado_ordenes_home(estado_orden = null) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listado_ordenes_home/${estado_orden}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function getOrdenesNotify() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/getOrdenesNotify`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function getOrdenesNotifyPT() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/getOrdenesNotifyPT`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listado_detalle_ordenes_home(id_orden) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listado_detalle_ordenes_home/${id_orden}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function aceptar_orden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/aceptar_orden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function rechazar_orden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/rechazar_orden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function despachar_orden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/despachar_orden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listar_combo_bodegas() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listar_combo_bodegas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listar_combos_motivos() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listar_combos_motivos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

////// Hormigon /////

function listado_orden_hormigon(id_orden) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    body: JSON.stringify(),
  };
  return fetch(`${Url}ordenes/listado_orden_hormigon/${id_orden}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listado_hormigon(estado_hormigon) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    body: JSON.stringify(),
  };
  return fetch(`${Url}ordenes/listado_hormigon/${estado_hormigon}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listado_ordenes_hormigon(estado_orden = null) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    body: JSON.stringify(),
  };
  return fetch(`${Url}ordenes/listado_ordenes_hormigon/${estado_orden}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function asignada_hormigon(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/asignada_hormigon`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function programacion_hormigon(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/programacion_hormigon`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function entregada_hormigon(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/entregada_hormigon`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function anuladas_hormigon(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/anuladas_hormigon`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function cambiar_cantidad_hormigon(data){
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/cambiar_cantidad_hormigon`,requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listar_motivos_hormigon() {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    body: JSON.stringify(),
  };
  return fetch(`${Url}ordenes/listar_motivos_hormigon`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function aceptar_shop(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/aceptar_shop`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function programacion_shop(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/programacion_shop`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function entregada_shop(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/entregada_shop`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function anuladas_shop(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/anuladas_shop`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}
// logistica

function listar_combos_motivos_Log(tipo_motivo) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listar_combos_motivos_Log/${tipo_motivo}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function retirar_orden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/retirar_orden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function entregada_orden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/entregada_orden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function devueltas_orden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/devueltas_orden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function listado_ordenes_home_admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listado_ordenes_home_admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function orden_home_logistica(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/orden_home_logistica`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}


function ordenlogistica(id) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/ordenlogistica/${id}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}


function cambiar_cantidad(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/cambiar_cantidad`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}


function CargaMasivaDeHormigon(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/CargaMasivaDeHormigon`,
    requestOptions
  ).then(HandleResponse);
} 


function listado_ordenes_Admin(estado_orden = null) {
  const requestOptions = {
    method: "GET",
    headers: AuthHeader(),
    // body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/listado_ordenes_Admin/${estado_orden}`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function aceptar_Admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/aceptar_Admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function programacion_Admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/programacion_Admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function entregada_Admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/entregada_Admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function aceptar_orden_Admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/aceptar_orden_Admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function rechazar_Admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/rechazar_Admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}

function despachar_Admin(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/despachar_Admin`, requestOptions)
    .then(HandleResponse)
    .then((response) => {

      return response;
    });
}