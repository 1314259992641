import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//servicios paraa lo relacion con solo párametros , esto se engloba como analisis
export const PedidosService = {
  //ordenes
  listarCuposDeCasa,
  filtrarOrdenes,
  listarDetalleOrdenes,
  actualizarOrden,
  ejecutarProceso,
  obtenerOrdenInformacionPDF,
  listarHistoricoEstados,
  listarEstados,
  listarTiposDocumentos,
  listarDocumentosPorOrden,
  RegistroDocumentos,
  bajarArchivo,
  ejecutarProcesoOrdenes,
  listarMovimientosOrden,
  obtenerOrden,
  procesarOrden,

  //proveedor
  obtenerProveedores,
  validarProveedor,
  crearProveedor,
  actualizarProveedor,
  eliminarProveedor,
  llenarComboProveedor,

  //conciliar ordenes
  listarOrdenesConciliar,
  conciliarOrden,

  //actuaizar orden
  actualizarDetalleOrden,

  //datos facturacion
  obtenerDatosCliente,
  obtenerMetodosPago,

  obtenerOrdenesGeneradas,
  listarEstadosHijos,

  //dashboard
  listarNotificacionesOrden,

  comboProyectos,
  crearProyecto,

  listarProveedores,
  cargarTerminos,
  generarExcelOrdenes,
};


//Ordenes
function   listarCuposDeCasa(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}pedidos/listarCuposDeCasa`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}

function   generarExcelOrdenes(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/generarExcelOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}


function   cargarTerminos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/cargarTerminos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function  listarProveedores(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarProveedores`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function  crearProyecto(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/crearProyecto`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function comboProyectos(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/comboProyectos`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function listarNotificacionesOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarNotificacionesOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}



function obtenerOrdenesGeneradas(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerOrdenesGeneradas`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function procesarOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/procesarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerMetodosPago(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerMetodosPago`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerDatosCliente(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerDatosCliente`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/obtenerOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function listarMovimientosOrden(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarMovimientosOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

//proveedor
function llenarComboProveedor(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}proveedor/llenarComboProveedor`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return NetworkService.errorRed();
    });
}

function obtenerProveedores(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(
    `${Url}proveedor/obtenerProveedor`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}

function validarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/validarProveedor`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}

function crearProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/crearProveedor`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}
function actualizarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/actualizarProveedor`,
    requestOptions
  ).then(HandleResponse)
  .catch(() => {
    return NetworkService.errorRed();
  });
}
function eliminarProveedor(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}proveedor/eliminarProveedor`,
    requestOptions
  ).then(HandleResponse);
  
}



function filtrarOrdenes(data) {
  debugger;
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}ordenes/filtrarOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}


function  listarDetalleOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/listarDetalleOrdenes`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${Url}analisis/actualizarDefiniciones`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function ejecutarProceso(data) {

  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${Url}ordenes/ejecutarProceso`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function actualizarOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/actualizarOrden`,
    requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerOrdenInformacionPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/crearPDForden`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function listarHistoricoEstados(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarHistoricoEstados`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarEstados(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarEstados`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarEstadosHijos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarEstadosHijos`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function listarTiposDocumentos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarTiposDocumentos`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function listarDocumentosPorOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/listarDocumentosPorOrden`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function RegistroDocumentos(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/RegistroDocumentos`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}



function bajarArchivo(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/bajarArchivo`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}


function ejecutarProcesoOrdenes(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${Url}ordenes/ejecutarProceso`,
     requestOptions
  )
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
 
//conciliar ordenes
function listarOrdenesConciliar(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}conciliar/listarOrdenesConciliar`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}

function conciliarOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}conciliar/conciliarOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}


function actualizarDetalleOrden(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}ordenes/actualizarDetalleOrden`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      
      return response;
    });
}