import { Url } from "./Url.service";
import { HandleResponse, AuthHeader } from "../_helpers";
import { NetworkService } from "./Network.service";

//devuelve las funciones
export const PreciosService = {
  //comunicaciones
  listarListadoPrecios,
  filtrarListadoPrecios,
  crearListadoPrecios,
  combosInformacion,
  obtenerListadoEspecifico,
  eliminarDetalleListaPrecios,
  CrearPrecioD,
  getPrecioDescripcion,
  UpdatePrecioD,
  EliminarPrecioD,
  validarPrecioD,
};

function eliminarDetalleListaPrecios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}precios/eliminarDetalleListaPrecios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function listarListadoPrecios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}precios/listarListadoPrecios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function filtrarListadoPrecios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}precios/filtrarListadoPrecios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function crearListadoPrecios(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}precios/crearListadoPrecios`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function combosInformacion(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };
  return fetch(`${Url}precios/combosInformacion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function obtenerListadoEspecifico(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}precios/obtenerListadoEspecifico`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}
/*function crearProspecto(data) {
  const requestOptions = {
      method: "POST",
      headers: AuthHeader(),
      body: JSON.stringify(data),
  };
  return fetch(`${Url}prospectos/crearProspecto`, requestOptions).then(HandleResponse);
}*/

///////             PRECIO DESCRIPCION

function getPrecioDescripcion(data) {
  const requestOptions = {
    method: "post",
    headers: AuthHeader(),
    body: JSON.stringify({ estado: data }),
  };

  return fetch(`${Url}precioDescripcion/getPrecioDescripcion`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function CrearPrecioD(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}precioDescripcion/CrearPrecioD`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function UpdatePrecioD(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}precioDescripcion/UpdatePrecioD`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function EliminarPrecioD(data) {
  const requestOptions = {
    method: "PUT",
    headers: AuthHeader(),
    body: JSON.stringify({ data }),
  };
  return fetch(`${Url}precioDescripcion/EliminarPrecioD`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

function validarPrecioD(data) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${Url}precioDescripcion/validarPrecioD`, requestOptions)
    .then(HandleResponse)
    .then((response) => {
      return response;
    });
}

//---------------Fin Descripcion de Precio--------------------
